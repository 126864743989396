import * as Types from './generated';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetcher } from './graphql-client';
export const OrgContainerOrganizationFragmentDoc = `
    fragment OrgContainerOrganization on Organization {
  id
  avatar
  buildCreditsAvailable
  memberTotal
  name
  plan
  seats
  slug
  studioSeatsPurchased
  subscription {
    id
    limits
    quantity
  }
  trialNative {
    id
    expired
  }
  users {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const OrgContainerUserFragmentDoc = `
    fragment OrgContainerUser on User {
  userId
  email
  hasStudio
  name
  dashMetadata {
    data
  }
}
    `;
export const OrgPageOrganizationFragmentDoc = `
    fragment OrgPageOrganization on Organization {
  id
  avatar
  name
  slug
  members {
    totalCount
  }
}
    `;
export const AutomationEditFormChannelFragmentDoc = `
    fragment AutomationEditFormChannel on Channel {
  __typename
  channelId: id
  name
}
    `;
export const AutomationEditFormDistCredentialFragmentDoc = `
    fragment AutomationEditFormDistCredential on DistributionCredential {
  __typename
  distributionCredentialId: id
  name
}
    `;
export const BuildStackDetailsFragmentDoc = `
    fragment BuildStackDetails on BuildStack {
  id
  available
  eolDate
  friendlyName
  latest
  name
  platform
  precedence
  type
  softwareVersions {
    name
    version
  }
}
    `;
export const ProvisionProfileDetailsFragmentDoc = `
    fragment ProvisionProfileDetails on ProvisioningProfile {
  id
  applicationIdentifier
  filename
}
    `;
export const CertificateDetailsFragmentDoc = `
    fragment CertificateDetails on Certificate {
  id
  name
  tag
  type
  credentials {
    ios {
      filename
      fingerprint
      notValidAfter
      notValidBefore
      subjectCommonName
      provisioningProfiles {
        ...ProvisionProfileDetails
      }
    }
    android {
      filename
      fingerprint
      keyAlias
      notValidAfter
      notValidBefore
      subjectCommonName
    }
  }
}
    ${ProvisionProfileDetailsFragmentDoc}`;
export const AutomationsListFragmentDoc = `
    fragment AutomationsList on Automation {
  __typename
  automationId
  type
  enabled
  name
  platform
  ref
  environment {
    name
  }
  nativeConfig {
    name
  }
  notifications {
    edges {
      node {
        ... on DeployBuildWebhookNotification {
          url
        }
        ... on PackageBuildWebhookNotification {
          url
        }
      }
    }
  }
  stack {
    ...BuildStackDetails
  }
  ... on DeployBuildAutomation {
    channels {
      edges {
        node {
          __typename
          channelId: id
          name
        }
      }
    }
  }
  ... on PackageBuildAutomation {
    buildType
    distributionCredential {
      __typename
      name
      distributionCredentialId: id
    }
    profile {
      ...CertificateDetails
    }
  }
}
    ${BuildStackDetailsFragmentDoc}
${CertificateDetailsFragmentDoc}`;
export const BuildCompileNewChannelFragmentDoc = `
    fragment BuildCompileNewChannel on Channel {
  __typename
  channelId: id
  name
}
    `;
export const NativeConfigListFragmentDoc = `
    fragment NativeConfigList on NativeConfig {
  nativeConfigId
  name
  configObject {
    base {
      bundleId
      name
    }
    ionic {
      appId
      channelName
      disableDeploy
      maxStore
      minBackgroundDuration
      updateMethod
    }
  }
}
    `;
export const NativePreviewFragmentDoc = `
    fragment NativePreview on NativePreview {
  appetizeUrl
}
    `;
export const BuildListDataFragmentDoc = `
    fragment BuildListData on App {
  id
  name
  nativeType
  webPreview
  organization {
    id
    name
    slug
    buildCreditsAvailable
  }
}
    `;
export const PackageCertsAppFragmentDoc = `
    fragment PackageCertsApp on App {
  id
  name
  nativeType
  webPreview
  organization {
    name
    slug
  }
  certificates {
    edges {
      node {
        ...CertificateDetails
      }
    }
  }
}
    ${CertificateDetailsFragmentDoc}`;
export const CommitListDetailsFragmentDoc = `
    fragment CommitListDetails on Commit {
  id
  created
  note
  ref
  sha
  commitRepoLink
  repository {
    type
  }
  user {
    name
    picture
    username
  }
  commitRepoLink
  repository {
    type
  }
}
    `;
export const BinaryDeploymentDetailsFragmentDoc = `
    fragment BinaryDeploymentDetails on BinaryDeployment {
  __typename
  id
  build {
    created
    jobId
    number
    platform
    commit {
      created
      note
      ref
      sha
      commitRepoLink
      repository {
        type
      }
      user {
        name
        picture
        username
      }
    }
    uuid
  }
  status
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
}
    `;
export const LiveUpdateDeploymentDetailsFragmentDoc = `
    fragment LiveUpdateDeploymentDetails on LiveUpdateDeployment {
  __typename
  id
  build {
    created
    jobId
    number
    platform
    commit {
      created
      note
      ref
      sha
      commitRepoLink
      repository {
        type
      }
      user {
        name
        picture
        username
      }
    }
    uuid
  }
  channel {
    id
    name
    build {
      jobId
    }
  }
  status
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
}
    `;
export const LiveUpdateDestinationListChannelFragmentDoc = `
    fragment LiveUpdateDestinationListChannel on Channel {
  channelId: id
  name
  build {
    platform
    number
  }
  updated
}
    `;
export const StoreDestinationListGooglePlayCredentialFragmentDoc = `
    fragment StoreDestinationListGooglePlayCredential on GooglePlayDistCredential {
  distributionCredentialId: id
  artifactUploadType
  name
  releaseStatus
  packageName
  track
  type
  latestDeployment {
    completed
    id
    build {
      number
      platform
    }
  }
}
    `;
export const StoreDestinationListItunesCredentialFragmentDoc = `
    fragment StoreDestinationListItunesCredential on ItunesConnectDistributionCredential {
  distributionCredentialId: id
  artifactUploadType
  name
  appleAppId
  teamName
  teamId
  type
  userName
  latestDeployment {
    completed
    id
    build {
      number
      platform
    }
  }
}
    `;
export const AppBuildSettingsAppFragmentDoc = `
    fragment AppBuildSettingsApp on App {
  id
  dependencyCaching
  name
  nativeType
  webPreview
  organization {
    id
    name
    slug
  }
}
    `;
export const AppBuildCacheFragmentDoc = `
    fragment AppBuildCache on BuildCache {
  key
  size
  modified
}
    `;
export const GitSettingsAppFragmentDoc = `
    fragment GitSettingsApp on App {
  id
  name
  association {
    repository {
      cloneUrl
    }
  }
  organization {
    id
    name
    slug
  }
}
    `;
export const OrgMembersTeamFragmentDoc = `
    fragment OrgMembersTeam on Team {
  id
  color
  description
  name
  apps {
    totalCount
  }
  users {
    totalCount
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const MemberDetailFragmentDoc = `
    fragment MemberDetail on Member {
  email
  hasStudio
  name
  picture
  role
  userId
}
    `;
export const TeamListFragmentDoc = `
    fragment TeamList on Team {
  id
  color
  description
  name
  apps {
    totalCount
  }
  users {
    totalCount
  }
}
    `;
export const MonthlyBuildUsageFragmentDoc = `
    fragment MonthlyBuildUsage on AppBuildUsage {
  total
  details {
    total
    tags {
      name
      value
    }
  }
}
    `;
export const MonthlyAppUsageFragmentDoc = `
    fragment MonthlyAppUsage on App {
  id
  name
  deleted
  monthlyBuildCredits {
    ...MonthlyBuildUsage
  }
  monthlyBuildMinutes {
    ...MonthlyBuildUsage
  }
  monthlyDeployCount
  monthlyActiveUserCount
}
    ${MonthlyBuildUsageFragmentDoc}`;
export const UsageHistoryMonthlyUsageFragmentDoc = `
    fragment UsageHistoryMonthlyUsage on MonthlyUsage {
  total
  month
  startDate
  endDate
}
    `;
export const AppListFieldsFragmentDoc = `
    fragment AppListFields on App {
  id
  name
  slug
  created
  updated
  icon
  lastActivity
  webPreview
  nativeType
}
    `;
export const AppDetailFieldsFragmentDoc = `
    fragment AppDetailFields on App {
  id
  createdByWizard
  icon
  name
  slug
  webPreview
  nativeType
  association {
    repository {
      cloneUrl
    }
  }
  organization {
    id
    slug
    name
  }
}
    `;
export const SubscriptionFieldsFragmentDoc = `
    fragment SubscriptionFields on BillingSubscription {
  id
  name
  product {
    id
    name
    active
  }
  quantity
  limits
  legacyProductId
  stripeId
  stripeNickname
}
    `;
export const BuildListBinaryDeploymentFragmentDoc = `
    fragment BuildListBinaryDeployment on BinaryDeployment {
  __typename
  id
  completed
  status
  build {
    platform
    jobId
  }
  distributionCredential {
    id: id
    name
    latestDeployment {
      id
      completed
    }
    latestFailedDeployment {
      id
      completed
    }
  }
}
    `;
export const BuildListChannelDeploymentFragmentDoc = `
    fragment BuildListChannelDeployment on LiveUpdateDeployment {
  __typename
  id
  completed
  status
  build {
    platform
    jobId
  }
  channel {
    id: id
    name
    latestDeployment {
      id
      completed
    }
    latestFailedDeployment {
      id
      completed
    }
  }
}
    `;
export const CommitFieldsFragmentDoc = `
    fragment CommitFields on Commit {
  id
  beforeSha
  created
  commitRepoLink
  note
  ref
  refType
  sha
  shortSha
  uuid
  repository {
    id
    htmlUrl
    type
  }
  user {
    ... on User {
      __typename
      name
      username
      profile
      picture
      id
    }
    ... on GitUser {
      __typename
      name
      username
      profile
      picture
      id
    }
  }
}
    `;
export const BuildListFieldsFragmentDoc = `
    fragment BuildListFields on Build {
  __typename
  number
  created
  started
  building
  finished
  canceled
  jobId
  platform
  state
  type
  callerId
  uuid
  rerunOf
  customerUploaded
  customerHosted
  traceOnS3
  caller {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  environmentVariables {
    name
    value
  }
  environmentSecrets {
    name
    value
  }
  deployments {
    edges {
      node {
        __typename
        id
        completed
        status
        build {
          platform
          jobId
        }
        ... on BinaryDeployment {
          ...BuildListBinaryDeployment
        }
        ... on LiveUpdateDeployment {
          ...BuildListChannelDeployment
        }
      }
    }
  }
  app {
    id
  }
  automation {
    name
    automationId
  }
  commit {
    ...CommitFields
  }
  environment {
    id
    name
  }
  stack {
    id
    available
    eolDate
    friendlyName
    latest
    name
    platform
    precedence
    softwareVersions {
      name
      version
    }
    type
  }
}
    ${BuildListBinaryDeploymentFragmentDoc}
${BuildListChannelDeploymentFragmentDoc}
${CommitFieldsFragmentDoc}`;
export const BuildDetailFieldsFragmentDoc = `
    fragment BuildDetailFields on Build {
  ...BuildListFields
  trace
}
    ${BuildListFieldsFragmentDoc}`;
export const DeployCommitFieldsFragmentDoc = `
    fragment DeployCommitFields on Commit {
  id
  beforeSha
  created
  commitRepoLink
  note
  ref
  refType
  sha
  shortSha
  uuid
  repository {
    id
    htmlUrl
    type
  }
  user {
    ... on User {
      __typename
      name
      username
      profile
      picture
      id
    }
    ... on GitUser {
      __typename
      name
      username
      profile
      picture
      id
    }
  }
}
    `;
export const DeployBuildListFieldsFragmentDoc = `
    fragment DeployBuildListFields on DeployBuild {
  id
  number
  created
  started
  building
  finished
  canceled
  jobId
  platform
  state
  type
  androidEq
  androidMin
  androidMax
  iosEq
  iosMin
  iosMax
  previewHash
  rerunOf
  customerUploaded
  customerHosted
  traceOnS3
  artifacts {
    edges {
      node {
        artifactType
        downloadUrl
        name
        artifactUrl
      }
    }
  }
  environmentVariables {
    name
    value
  }
  environmentSecrets {
    name
    value
  }
  callerId
  uuid
  caller {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  deployments {
    edges {
      node {
        __typename
        id
        completed
        status
        build {
          platform
          jobId
        }
        ... on BinaryDeployment {
          __typename
          id
          completed
          status
          build {
            platform
            jobId
          }
          distributionCredential {
            id: id
            name
            latestDeployment {
              id
              completed
            }
            latestFailedDeployment {
              id
              completed
            }
          }
        }
        ... on LiveUpdateDeployment {
          __typename
          id
          completed
          status
          build {
            platform
            jobId
          }
          channel {
            id: id
            name
            latestDeployment {
              id
              completed
            }
            latestFailedDeployment {
              id
              completed
            }
          }
        }
      }
    }
  }
  app {
    id
    nativeType
    webPreview
  }
  automation {
    name
    automationId
  }
  commit {
    ...DeployCommitFields
  }
  environment {
    id
    name
  }
  stack {
    id
    available
    eolDate
    friendlyName
    latest
    name
    platform
    precedence
    softwareVersions {
      name
      version
    }
    type
  }
}
    ${DeployCommitFieldsFragmentDoc}`;
export const DeployBuildDetailChannelFragmentDoc = `
    fragment DeployBuildDetailChannel on Channel {
  __typename
  channelId: id
  name
}
    `;
export const DeployBuildDetailFieldsFragmentDoc = `
    fragment DeployBuildDetailFields on DeployBuild {
  ...DeployBuildListFields
  trace
  channels {
    edges {
      node {
        ... on Channel {
          ...DeployBuildDetailChannel
        }
      }
    }
  }
}
    ${DeployBuildListFieldsFragmentDoc}
${DeployBuildDetailChannelFragmentDoc}`;
export const PackageCommitFieldsFragmentDoc = `
    fragment PackageCommitFields on Commit {
  id
  beforeSha
  created
  commitRepoLink
  note
  ref
  refType
  sha
  shortSha
  uuid
  repository {
    id
    htmlUrl
    type
  }
  user {
    ... on User {
      __typename
      name
      username
      profile
      picture
      id
    }
    ... on GitUser {
      __typename
      name
      username
      profile
      picture
      id
    }
  }
}
    `;
export const NativeConfigFieldsFragmentDoc = `
    fragment NativeConfigFields on NativeConfig {
  id
  config
  created
  name
  configObject {
    ionic {
      appId
      channelName
      updateMethod
      maxStore
      minBackgroundDuration
      disableDeploy
    }
    base {
      bundleId
      name
    }
  }
}
    `;
export const PackageBuildListFieldsFragmentDoc = `
    fragment PackageBuildListFields on PackageBuild {
  id
  number
  buildType
  created
  started
  building
  finished
  canceled
  credits
  jobId
  platform
  state
  type
  buildType
  rerunOf
  customerUploaded
  customerHosted
  traceOnS3
  nativePreview {
    appetizeUrl
  }
  profile {
    tag
    name
    credentials {
      ios {
        notValidAfter
      }
      android {
        notValidAfter
      }
    }
  }
  artifacts {
    edges {
      node {
        artifactType
        downloadUrl
        name
        artifactUrl
      }
    }
  }
  environmentVariables {
    name
    value
  }
  environmentSecrets {
    name
    value
  }
  callerId
  uuid
  caller {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  deployments {
    edges {
      node {
        __typename
        id
        completed
        status
        build {
          platform
          jobId
        }
        ... on BinaryDeployment {
          __typename
          id
          completed
          status
          build {
            platform
            jobId
          }
          distributionCredential {
            id: id
            name
            latestDeployment {
              id
              completed
            }
            latestFailedDeployment {
              id
              completed
            }
          }
        }
        ... on LiveUpdateDeployment {
          __typename
          id
          completed
          status
          build {
            platform
            jobId
          }
          channel {
            id: id
            name
            latestDeployment {
              id
              completed
            }
            latestFailedDeployment {
              id
              completed
            }
          }
        }
      }
    }
  }
  app {
    id
  }
  commit {
    ...PackageCommitFields
  }
  automation {
    name
    automationId
  }
  distributionCredential {
    id
    created
    name
  }
  environment {
    id
    name
  }
  nativeConfig {
    ...NativeConfigFields
  }
  nativeConfigVariables {
    ionic {
      appId
      channelName
      updateMethod
      maxStore
      minBackgroundDuration
      disableDeploy
    }
    base {
      bundleId
      name
    }
  }
  stack {
    id
    available
    eolDate
    friendlyName
    latest
    name
    platform
    precedence
    softwareVersions {
      name
      version
    }
    type
  }
}
    ${PackageCommitFieldsFragmentDoc}
${NativeConfigFieldsFragmentDoc}`;
export const PackageBuildDetailFieldsFragmentDoc = `
    fragment PackageBuildDetailFields on PackageBuild {
  ...PackageBuildListFields
  trace
}
    ${PackageBuildListFieldsFragmentDoc}`;
export const DeploymentListFieldsFragmentDoc = `
    fragment DeploymentListFields on Deployment {
  __typename
  id
  status
  type
  triggeredBy
  build {
    created
    jobId
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...CommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
}
    ${CommitFieldsFragmentDoc}`;
export const DeploymentDetailFieldsFragmentDoc = `
    fragment DeploymentDetailFields on Deployment {
  __typename
  id
  status
  type
  triggeredBy
  build {
    type
    jobId
    state
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...CommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
}
    ${CommitFieldsFragmentDoc}`;
export const BinaryDeploymentCommitFieldsFragmentDoc = `
    fragment BinaryDeploymentCommitFields on Commit {
  id
  beforeSha
  created
  commitRepoLink
  note
  ref
  refType
  sha
  shortSha
  uuid
  repository {
    id
    htmlUrl
    type
  }
  user {
    ... on User {
      __typename
      name
      username
      profile
      picture
      id
    }
    ... on GitUser {
      __typename
      name
      username
      profile
      picture
      id
    }
  }
}
    `;
export const BinaryDeploymentListFieldsFragmentDoc = `
    fragment BinaryDeploymentListFields on BinaryDeployment {
  id
  status
  type
  triggeredBy
  distributionBuild {
    jobId
  }
  build {
    created
    jobId
    type
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...BinaryDeploymentCommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  distributionCredential {
    __typename
    id
    name
    type
  }
  message
}
    ${BinaryDeploymentCommitFieldsFragmentDoc}`;
export const BinaryDeploymentDetailFieldsFragmentDoc = `
    fragment BinaryDeploymentDetailFields on BinaryDeployment {
  id
  status
  type
  triggeredBy
  distributionBuild {
    jobId
    distributionTrace
    type
    state
  }
  build {
    jobId
    state
    type
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...BinaryDeploymentCommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  distributionCredential {
    id
    name
    type
  }
  message
}
    ${BinaryDeploymentCommitFieldsFragmentDoc}`;
export const LiveUpdateDeploymentCommitFieldsFragmentDoc = `
    fragment LiveUpdateDeploymentCommitFields on Commit {
  id
  beforeSha
  created
  commitRepoLink
  note
  ref
  refType
  sha
  shortSha
  uuid
  repository {
    id
    htmlUrl
    type
  }
  user {
    ... on User {
      __typename
      name
      username
      profile
      picture
      id
    }
    ... on GitUser {
      __typename
      name
      username
      profile
      picture
      id
    }
  }
}
    `;
export const LiveUpdateDeploymentListFieldsFragmentDoc = `
    fragment LiveUpdateDeploymentListFields on LiveUpdateDeployment {
  id
  created
  status
  type
  triggeredBy
  build {
    created
    jobId
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...LiveUpdateDeploymentCommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  channel {
    id
    name
    build {
      jobId
    }
  }
}
    ${LiveUpdateDeploymentCommitFieldsFragmentDoc}`;
export const LiveUpdateDeploymentDetailFieldsFragmentDoc = `
    fragment LiveUpdateDeploymentDetailFields on LiveUpdateDeployment {
  id
  status
  type
  triggeredBy
  build {
    jobId
    state
    type
    number
    platform
    automation {
      automationId
      name
    }
    commit {
      ...LiveUpdateDeploymentCommitFields
    }
  }
  user {
    __typename
    name
    picture
    profile
    ... on User {
      id
      avatar
    }
    ... on GitUser {
      id
    }
  }
  channel {
    id
    name
    build {
      jobId
    }
  }
}
    ${LiveUpdateDeploymentCommitFieldsFragmentDoc}`;
export const E2EBuildStacksFragmentDoc = `
    fragment E2EBuildStacks on BuildStack {
  ...BuildStackDetails
  testDevices {
    id
    name
    osVersion
    platform
    type
    stack {
      name
      id
    }
  }
}
    ${BuildStackDetailsFragmentDoc}`;
export const E2ETestListFieldsFragmentDoc = `
    fragment E2ETestListFields on E2ETest {
  id
  jobId
  state
  created
  finished
  started
  duration
  commit {
    ...CommitFields
  }
  number
  pending
  platform
  device {
    name
    platform
    osVersion
    type
  }
  stack {
    name
    friendlyName
  }
  environment {
    name
  }
  type
}
    ${CommitFieldsFragmentDoc}`;
export const E2ETestFieldsFragmentDoc = `
    fragment E2ETestFields on E2ETest {
  ...E2ETestListFields
  trace
  distributionTrace
  jsonReport
  artifacts {
    edges {
      node {
        artifactUrl
        name
        downloadUrl
        contentType
      }
    }
  }
}
    ${E2ETestListFieldsFragmentDoc}`;
export const E2ETestDetailsFieldsFragmentDoc = `
    fragment E2ETestDetailsFields on E2ETest {
  ...E2ETestFields
  testRun {
    id
    runNumber
  }
}
    ${E2ETestFieldsFragmentDoc}`;
export const E2ETestRunListFieldsFragmentDoc = `
    fragment E2ETestRunListFields on E2ETestRun {
  id
  runNumber
  created
  label
  caller {
    name
    picture
  }
  app {
    id
  }
  started
  finished
  duration
  platforms
  state
  commit {
    id
    beforeSha
    created
    commitRepoLink
    note
    ref
    refType
    sha
    shortSha
    uuid
    repository {
      id
      cloneUrl
      fullName
      htmlUrl
      type
    }
    user {
      name
      username
      picture
    }
  }
  tests {
    ...E2ETestListFields
  }
}
    ${E2ETestListFieldsFragmentDoc}`;
export const E2ETestRunFieldsFragmentDoc = `
    fragment E2ETestRunFields on E2ETestRun {
  ...E2ETestRunListFields
  tests {
    trace
    distributionTrace
    jsonReport
    state
    artifacts {
      edges {
        node {
          artifactUrl
          name
          downloadUrl
          contentType
        }
      }
    }
  }
}
    ${E2ETestRunListFieldsFragmentDoc}`;
export const TrialDetailFieldsFragmentDoc = `
    fragment TrialDetailFields on Trial {
  id
  trialType
  start
  end
  expired
}
    `;
export const OrganizationFieldsFragmentDoc = `
    fragment OrganizationFields on Organization {
  id
  name
  slug
  plan
  qualifiesTrialNative
  buildCreditsAvailable
  trialNative {
    ...TrialDetailFields
  }
}
    ${TrialDetailFieldsFragmentDoc}`;
export const ProductKeyFieldsFragmentDoc = `
    fragment ProductKeyFields on NpmProductKey {
  key
  packages {
    edges {
      node {
        name
      }
    }
  }
}
    `;
export const S3DomainFieldsFragmentDoc = `
    fragment S3DomainFields on S3Domain {
  accessKeyId
  bucket
  domain
  enabled
}
    `;
export const SshKeyFieldsFragmentDoc = `
    fragment SSHKeyFields on SSHKey {
  id
  annotation
  created
  fingerprint
  method
  name
  pubkey
  sshKeyId
  updated
}
    `;
export const PersonalAccessTokenFieldsFragmentDoc = `
    fragment PersonalAccessTokenFields on PersonalAccessToken {
  id
  name
  userId
  expirationDate
  created
  lastUsed
  shortToken
}
    `;
export const UserDetailFieldsFragmentDoc = `
    fragment UserDetailFields on User {
  id
  created
  email
  name
  picture
  superUser
  username
  verified
  qualifiesTrialNative
  dashMetadata {
    data
  }
  trialNative {
    ...TrialDetailFields
  }
  personalAccessTokens {
    edges {
      node {
        ...PersonalAccessTokenFields
      }
    }
  }
}
    ${TrialDetailFieldsFragmentDoc}
${PersonalAccessTokenFieldsFragmentDoc}`;
export const GetDataForAccountSwitcherDropdownDocument = `
    query GetDataForAccountSwitcherDropdown($slug: String!) {
  organization(slug: $slug) {
    id
    avatar
  }
}
    `;
export const useGetDataForAccountSwitcherDropdownQuery = <
      TData = Types.GetDataForAccountSwitcherDropdownQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAccountSwitcherDropdownQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAccountSwitcherDropdownQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAccountSwitcherDropdownQuery, TError, TData>(
      ['GetDataForAccountSwitcherDropdown', variables],
      useFetcher<Types.GetDataForAccountSwitcherDropdownQuery, Types.GetDataForAccountSwitcherDropdownQueryVariables>(GetDataForAccountSwitcherDropdownDocument).bind(null, variables),
      options
    );
export const GetDataForNotificationBarDocument = `
    query GetDataForNotificationBar($slug: String!) {
  organization(slug: $slug) {
    id
    buildCreditsAvailable
    slug
    deployCount(months: 1) {
      total
    }
  }
  apps {
    totalCount
  }
}
    `;
export const useGetDataForNotificationBarQuery = <
      TData = Types.GetDataForNotificationBarQuery,
      TError = unknown
    >(
      variables: Types.GetDataForNotificationBarQueryVariables,
      options?: UseQueryOptions<Types.GetDataForNotificationBarQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForNotificationBarQuery, TError, TData>(
      ['GetDataForNotificationBar', variables],
      useFetcher<Types.GetDataForNotificationBarQuery, Types.GetDataForNotificationBarQueryVariables>(GetDataForNotificationBarDocument).bind(null, variables),
      options
    );
export const GetAppsForSearchDocument = `
    query GetAppsForSearch($nameLike: String!) {
  apps(nameLike: $nameLike) {
    edges {
      node {
        id
        name
        icon
      }
    }
  }
}
    `;
export const useGetAppsForSearchQuery = <
      TData = Types.GetAppsForSearchQuery,
      TError = unknown
    >(
      variables: Types.GetAppsForSearchQueryVariables,
      options?: UseQueryOptions<Types.GetAppsForSearchQuery, TError, TData>
    ) =>
    useQuery<Types.GetAppsForSearchQuery, TError, TData>(
      ['GetAppsForSearch', variables],
      useFetcher<Types.GetAppsForSearchQuery, Types.GetAppsForSearchQueryVariables>(GetAppsForSearchDocument).bind(null, variables),
      options
    );
export const BootstrapAppDocument = `
    query BootstrapApp {
  viewer {
    apps {
      totalCount
    }
    subscriptions {
      name
    }
    dashMetadata {
      data
    }
    organizations {
      edges {
        node {
          id
          avatar
          created
          name
          plan
          memberTotal
          slug
          apps {
            totalCount
          }
        }
      }
    }
  }
}
    `;
export const useBootstrapAppQuery = <
      TData = Types.BootstrapAppQuery,
      TError = unknown
    >(
      variables?: Types.BootstrapAppQueryVariables,
      options?: UseQueryOptions<Types.BootstrapAppQuery, TError, TData>
    ) =>
    useQuery<Types.BootstrapAppQuery, TError, TData>(
      variables === undefined ? ['BootstrapApp'] : ['BootstrapApp', variables],
      useFetcher<Types.BootstrapAppQuery, Types.BootstrapAppQueryVariables>(BootstrapAppDocument).bind(null, variables),
      options
    );
export const GetDataForOrgContainerDocument = `
    query GetDataForOrgContainer($slug: String!) {
  organization(slug: $slug) {
    ...OrgContainerOrganization
  }
  viewer {
    ...OrgContainerUser
  }
}
    ${OrgContainerOrganizationFragmentDoc}
${OrgContainerUserFragmentDoc}`;
export const useGetDataForOrgContainerQuery = <
      TData = Types.GetDataForOrgContainerQuery,
      TError = unknown
    >(
      variables: Types.GetDataForOrgContainerQueryVariables,
      options?: UseQueryOptions<Types.GetDataForOrgContainerQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForOrgContainerQuery, TError, TData>(
      ['GetDataForOrgContainer', variables],
      useFetcher<Types.GetDataForOrgContainerQuery, Types.GetDataForOrgContainerQueryVariables>(GetDataForOrgContainerDocument).bind(null, variables),
      options
    );
export const GetDataForOrgsPageDocument = `
    query GetDataForOrgsPage {
  viewer {
    organizations {
      edges {
        node {
          ...OrgPageOrganization
        }
      }
    }
  }
}
    ${OrgPageOrganizationFragmentDoc}`;
export const useGetDataForOrgsPageQuery = <
      TData = Types.GetDataForOrgsPageQuery,
      TError = unknown
    >(
      variables?: Types.GetDataForOrgsPageQueryVariables,
      options?: UseQueryOptions<Types.GetDataForOrgsPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForOrgsPageQuery, TError, TData>(
      variables === undefined ? ['GetDataForOrgsPage'] : ['GetDataForOrgsPage', variables],
      useFetcher<Types.GetDataForOrgsPageQuery, Types.GetDataForOrgsPageQueryVariables>(GetDataForOrgsPageDocument).bind(null, variables),
      options
    );
export const ToggleAutomationDocument = `
    mutation ToggleAutomation($input: ToggleAutomationInput!) {
  toggleAutomation(input: $input) {
    automation {
      ...AutomationsList
    }
  }
}
    ${AutomationsListFragmentDoc}`;
export const useToggleAutomationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ToggleAutomationMutation, TError, Types.ToggleAutomationMutationVariables, TContext>) =>
    useMutation<Types.ToggleAutomationMutation, TError, Types.ToggleAutomationMutationVariables, TContext>(
      ['ToggleAutomation'],
      useFetcher<Types.ToggleAutomationMutation, Types.ToggleAutomationMutationVariables>(ToggleAutomationDocument),
      options
    );
export const GetDataForAutomationDetailDocument = `
    query GetDataForAutomationDetail($appId: String!, $automationId: Int!) {
  app(id: $appId) {
    id
    nativeType
    automation(automationId: $automationId) {
      __typename
      automationId
      type
      enabled
      name
      platform
      ref
      environment {
        name
      }
      nativeConfig {
        name
      }
      notifications {
        edges {
          node {
            ... on DeployBuildWebhookNotification {
              url
            }
            ... on PackageBuildWebhookNotification {
              url
            }
          }
        }
      }
      stack {
        ...BuildStackDetails
      }
      ... on DeployBuildAutomation {
        channels {
          edges {
            node {
              __typename
              channelId: id
              name
            }
          }
        }
      }
      ... on PackageBuildAutomation {
        buildType
        distributionCredential {
          __typename
          name
          distributionCredentialId: id
        }
        profile {
          ...CertificateDetails
        }
      }
    }
    organization {
      id
      buildCreditsAvailable
      slug
      name
    }
  }
  stacks {
    ...BuildStackDetails
  }
}
    ${BuildStackDetailsFragmentDoc}
${CertificateDetailsFragmentDoc}`;
export const useGetDataForAutomationDetailQuery = <
      TData = Types.GetDataForAutomationDetailQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAutomationDetailQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAutomationDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAutomationDetailQuery, TError, TData>(
      ['GetDataForAutomationDetail', variables],
      useFetcher<Types.GetDataForAutomationDetailQuery, Types.GetDataForAutomationDetailQueryVariables>(GetDataForAutomationDetailDocument).bind(null, variables),
      options
    );
export const GetDataForAutomationEditFormDocument = `
    query GetDataForAutomationEditForm($appId: String!) {
  app(id: $appId) {
    id
    nativeType
    webPreview
    organization {
      id
    }
    environments {
      edges {
        node {
          environmentId
          name
        }
      }
    }
    channels {
      edges {
        node {
          ...AutomationEditFormChannel
        }
      }
    }
    distributionCredentials {
      edges {
        node {
          ...AutomationEditFormDistCredential
        }
      }
    }
    nativeConfigs {
      edges {
        node {
          name
          nativeConfigId
        }
      }
    }
  }
}
    ${AutomationEditFormChannelFragmentDoc}
${AutomationEditFormDistCredentialFragmentDoc}`;
export const useGetDataForAutomationEditFormQuery = <
      TData = Types.GetDataForAutomationEditFormQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAutomationEditFormQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAutomationEditFormQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAutomationEditFormQuery, TError, TData>(
      ['GetDataForAutomationEditForm', variables],
      useFetcher<Types.GetDataForAutomationEditFormQuery, Types.GetDataForAutomationEditFormQueryVariables>(GetDataForAutomationEditFormDocument).bind(null, variables),
      options
    );
export const GetCertificatesForAutomationEditFormDocument = `
    query GetCertificatesForAutomationEditForm($appId: String!) {
  app(id: $appId) {
    certificates {
      edges {
        node {
          ...CertificateDetails
        }
      }
    }
  }
}
    ${CertificateDetailsFragmentDoc}`;
export const useGetCertificatesForAutomationEditFormQuery = <
      TData = Types.GetCertificatesForAutomationEditFormQuery,
      TError = unknown
    >(
      variables: Types.GetCertificatesForAutomationEditFormQueryVariables,
      options?: UseQueryOptions<Types.GetCertificatesForAutomationEditFormQuery, TError, TData>
    ) =>
    useQuery<Types.GetCertificatesForAutomationEditFormQuery, TError, TData>(
      ['GetCertificatesForAutomationEditForm', variables],
      useFetcher<Types.GetCertificatesForAutomationEditFormQuery, Types.GetCertificatesForAutomationEditFormQueryVariables>(GetCertificatesForAutomationEditFormDocument).bind(null, variables),
      options
    );
export const GetDataForAutomationListDocument = `
    query GetDataForAutomationList($appId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $appId) {
    id
    name
    automations(first: $first, last: $last, after: $after, before: $before) {
      edges {
        node {
          ...AutomationsList
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    organization {
      id
      name
      slug
    }
  }
}
    ${AutomationsListFragmentDoc}`;
export const useGetDataForAutomationListQuery = <
      TData = Types.GetDataForAutomationListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAutomationListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAutomationListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAutomationListQuery, TError, TData>(
      ['GetDataForAutomationList', variables],
      useFetcher<Types.GetDataForAutomationListQuery, Types.GetDataForAutomationListQueryVariables>(GetDataForAutomationListDocument).bind(null, variables),
      options
    );
export const DeleteAutomationDocument = `
    mutation DeleteAutomation($input: DeleteAutomationInput!) {
  deleteAutomation(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteAutomationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteAutomationMutation, TError, Types.DeleteAutomationMutationVariables, TContext>) =>
    useMutation<Types.DeleteAutomationMutation, TError, Types.DeleteAutomationMutationVariables, TContext>(
      ['DeleteAutomation'],
      useFetcher<Types.DeleteAutomationMutation, Types.DeleteAutomationMutationVariables>(DeleteAutomationDocument),
      options
    );
export const EnableWebPreviewOnAppFromNewBuildDocument = `
    mutation EnableWebPreviewOnAppFromNewBuild($appId: String!, $webPreview: Boolean!) {
  updateApp(input: {appId: $appId, webPreview: $webPreview}) {
    app {
      id
    }
  }
}
    `;
export const useEnableWebPreviewOnAppFromNewBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.EnableWebPreviewOnAppFromNewBuildMutation, TError, Types.EnableWebPreviewOnAppFromNewBuildMutationVariables, TContext>) =>
    useMutation<Types.EnableWebPreviewOnAppFromNewBuildMutation, TError, Types.EnableWebPreviewOnAppFromNewBuildMutationVariables, TContext>(
      ['EnableWebPreviewOnAppFromNewBuild'],
      useFetcher<Types.EnableWebPreviewOnAppFromNewBuildMutation, Types.EnableWebPreviewOnAppFromNewBuildMutationVariables>(EnableWebPreviewOnAppFromNewBuildDocument),
      options
    );
export const GetDataForBuildCompileNewDocument = `
    query GetDataForBuildCompileNew($appId: String!) {
  app(id: $appId) {
    id
    dependencyCaching
    environments {
      edges {
        node {
          environmentId
          name
        }
      }
    }
    channels {
      edges {
        node {
          ...BuildCompileNewChannel
        }
      }
    }
    nativeConfigs {
      edges {
        node {
          name
          nativeConfigId
        }
      }
    }
  }
}
    ${BuildCompileNewChannelFragmentDoc}`;
export const useGetDataForBuildCompileNewQuery = <
      TData = Types.GetDataForBuildCompileNewQuery,
      TError = unknown
    >(
      variables: Types.GetDataForBuildCompileNewQueryVariables,
      options?: UseQueryOptions<Types.GetDataForBuildCompileNewQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForBuildCompileNewQuery, TError, TData>(
      ['GetDataForBuildCompileNew', variables],
      useFetcher<Types.GetDataForBuildCompileNewQuery, Types.GetDataForBuildCompileNewQueryVariables>(GetDataForBuildCompileNewDocument).bind(null, variables),
      options
    );
export const GetDataForEnvironmentListDocument = `
    query GetDataForEnvironmentList($appId: String!) {
  app(id: $appId) {
    id
    name
    organization {
      id
      name
      slug
    }
    environments {
      edges {
        node {
          environmentId
          name
          config {
            name
            value
          }
          created
          secrets {
            name
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForEnvironmentListQuery = <
      TData = Types.GetDataForEnvironmentListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForEnvironmentListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForEnvironmentListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForEnvironmentListQuery, TError, TData>(
      ['GetDataForEnvironmentList', variables],
      useFetcher<Types.GetDataForEnvironmentListQuery, Types.GetDataForEnvironmentListQueryVariables>(GetDataForEnvironmentListDocument).bind(null, variables),
      options
    );
export const GetDataForNativeConfigListDocument = `
    query GetDataForNativeConfigList($appId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $appId) {
    id
    name
    nativeConfigs(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...NativeConfigList
        }
      }
    }
    organization {
      id
      name
      slug
      subscription {
        limits
      }
    }
  }
}
    ${NativeConfigListFragmentDoc}`;
export const useGetDataForNativeConfigListQuery = <
      TData = Types.GetDataForNativeConfigListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForNativeConfigListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForNativeConfigListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForNativeConfigListQuery, TError, TData>(
      ['GetDataForNativeConfigList', variables],
      useFetcher<Types.GetDataForNativeConfigListQuery, Types.GetDataForNativeConfigListQueryVariables>(GetDataForNativeConfigListDocument).bind(null, variables),
      options
    );
export const CreateNativeConfigDocument = `
    mutation CreateNativeConfig($input: CreateNativeConfigInput!) {
  createNativeConfig(input: $input) {
    nativeConfig {
      ...NativeConfigList
    }
  }
}
    ${NativeConfigListFragmentDoc}`;
export const useCreateNativeConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateNativeConfigMutation, TError, Types.CreateNativeConfigMutationVariables, TContext>) =>
    useMutation<Types.CreateNativeConfigMutation, TError, Types.CreateNativeConfigMutationVariables, TContext>(
      ['CreateNativeConfig'],
      useFetcher<Types.CreateNativeConfigMutation, Types.CreateNativeConfigMutationVariables>(CreateNativeConfigDocument),
      options
    );
export const DeleteNativeConfigDocument = `
    mutation DeleteNativeConfig($input: DeleteNativeConfigInput!) {
  deleteNativeConfig(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteNativeConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteNativeConfigMutation, TError, Types.DeleteNativeConfigMutationVariables, TContext>) =>
    useMutation<Types.DeleteNativeConfigMutation, TError, Types.DeleteNativeConfigMutationVariables, TContext>(
      ['DeleteNativeConfig'],
      useFetcher<Types.DeleteNativeConfigMutation, Types.DeleteNativeConfigMutationVariables>(DeleteNativeConfigDocument),
      options
    );
export const UpdateNativeConfigDocument = `
    mutation UpdateNativeConfig($input: UpdateNativeConfigInput!) {
  updateNativeConfig(input: $input) {
    nativeConfig {
      ...NativeConfigList
    }
  }
}
    ${NativeConfigListFragmentDoc}`;
export const useUpdateNativeConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateNativeConfigMutation, TError, Types.UpdateNativeConfigMutationVariables, TContext>) =>
    useMutation<Types.UpdateNativeConfigMutation, TError, Types.UpdateNativeConfigMutationVariables, TContext>(
      ['UpdateNativeConfig'],
      useFetcher<Types.UpdateNativeConfigMutation, Types.UpdateNativeConfigMutationVariables>(UpdateNativeConfigDocument),
      options
    );
export const GetDataForBuildDetailDocument = `
    query GetDataForBuildDetail($appId: String!) {
  app(id: $appId) {
    id
    nativeType
    organization {
      id
      slug
      buildCreditsAvailable
    }
  }
}
    `;
export const useGetDataForBuildDetailQuery = <
      TData = Types.GetDataForBuildDetailQuery,
      TError = unknown
    >(
      variables: Types.GetDataForBuildDetailQueryVariables,
      options?: UseQueryOptions<Types.GetDataForBuildDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForBuildDetailQuery, TError, TData>(
      ['GetDataForBuildDetail', variables],
      useFetcher<Types.GetDataForBuildDetailQuery, Types.GetDataForBuildDetailQueryVariables>(GetDataForBuildDetailDocument).bind(null, variables),
      options
    );
export const GetDataForBuildListDocument = `
    query GetDataForBuildList($appId: String!) {
  app(id: $appId) {
    ...BuildListData
  }
}
    ${BuildListDataFragmentDoc}`;
export const useGetDataForBuildListQuery = <
      TData = Types.GetDataForBuildListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForBuildListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForBuildListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForBuildListQuery, TError, TData>(
      ['GetDataForBuildList', variables],
      useFetcher<Types.GetDataForBuildListQuery, Types.GetDataForBuildListQueryVariables>(GetDataForBuildListDocument).bind(null, variables),
      options
    );
export const GetDataForDeleteEnvironmentModalDocument = `
    query GetDataForDeleteEnvironmentModal($appId: String!) {
  app(id: $appId) {
    automations {
      edges {
        node {
          automationId
          name
          environment {
            environmentId
            name
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForDeleteEnvironmentModalQuery = <
      TData = Types.GetDataForDeleteEnvironmentModalQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDeleteEnvironmentModalQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDeleteEnvironmentModalQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDeleteEnvironmentModalQuery, TError, TData>(
      ['GetDataForDeleteEnvironmentModal', variables],
      useFetcher<Types.GetDataForDeleteEnvironmentModalQuery, Types.GetDataForDeleteEnvironmentModalQueryVariables>(GetDataForDeleteEnvironmentModalDocument).bind(null, variables),
      options
    );
export const GetDataForEnvironmentsDocument = `
    query GetDataForEnvironments($appId: String!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
    }
  }
}
    `;
export const useGetDataForEnvironmentsQuery = <
      TData = Types.GetDataForEnvironmentsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForEnvironmentsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForEnvironmentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForEnvironmentsQuery, TError, TData>(
      ['GetDataForEnvironments', variables],
      useFetcher<Types.GetDataForEnvironmentsQuery, Types.GetDataForEnvironmentsQueryVariables>(GetDataForEnvironmentsDocument).bind(null, variables),
      options
    );
export const GetDataForDeleteNativeConfigModalDocument = `
    query GetDataForDeleteNativeConfigModal($appId: String!) {
  app(id: $appId) {
    automations {
      edges {
        node {
          name
          nativeConfig {
            nativeConfigId
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForDeleteNativeConfigModalQuery = <
      TData = Types.GetDataForDeleteNativeConfigModalQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDeleteNativeConfigModalQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDeleteNativeConfigModalQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDeleteNativeConfigModalQuery, TError, TData>(
      ['GetDataForDeleteNativeConfigModal', variables],
      useFetcher<Types.GetDataForDeleteNativeConfigModalQuery, Types.GetDataForDeleteNativeConfigModalQueryVariables>(GetDataForDeleteNativeConfigModalDocument).bind(null, variables),
      options
    );
export const GetDataForNativeConfigEditModalDocument = `
    query GetDataForNativeConfigEditModal($appId: String!) {
  app(id: $appId) {
    id
    nativeType
  }
}
    `;
export const useGetDataForNativeConfigEditModalQuery = <
      TData = Types.GetDataForNativeConfigEditModalQuery,
      TError = unknown
    >(
      variables: Types.GetDataForNativeConfigEditModalQueryVariables,
      options?: UseQueryOptions<Types.GetDataForNativeConfigEditModalQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForNativeConfigEditModalQuery, TError, TData>(
      ['GetDataForNativeConfigEditModal', variables],
      useFetcher<Types.GetDataForNativeConfigEditModalQuery, Types.GetDataForNativeConfigEditModalQueryVariables>(GetDataForNativeConfigEditModalDocument).bind(null, variables),
      options
    );
export const GetAutomationsForAppDocument = `
    query GetAutomationsForApp($appId: String!) {
  app(id: $appId) {
    automations {
      edges {
        node {
          __typename
          automationId
          name
          ... on PackageBuildAutomation {
            profileId
          }
        }
      }
    }
  }
}
    `;
export const useGetAutomationsForAppQuery = <
      TData = Types.GetAutomationsForAppQuery,
      TError = unknown
    >(
      variables: Types.GetAutomationsForAppQueryVariables,
      options?: UseQueryOptions<Types.GetAutomationsForAppQuery, TError, TData>
    ) =>
    useQuery<Types.GetAutomationsForAppQuery, TError, TData>(
      ['GetAutomationsForApp', variables],
      useFetcher<Types.GetAutomationsForAppQuery, Types.GetAutomationsForAppQueryVariables>(GetAutomationsForAppDocument).bind(null, variables),
      options
    );
export const GetDataForPackageCertsDocument = `
    query GetDataForPackageCerts($appId: String!) {
  app(id: $appId) {
    ...PackageCertsApp
  }
}
    ${PackageCertsAppFragmentDoc}`;
export const useGetDataForPackageCertsQuery = <
      TData = Types.GetDataForPackageCertsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForPackageCertsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForPackageCertsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForPackageCertsQuery, TError, TData>(
      ['GetDataForPackageCerts', variables],
      useFetcher<Types.GetDataForPackageCertsQuery, Types.GetDataForPackageCertsQueryVariables>(GetDataForPackageCertsDocument).bind(null, variables),
      options
    );
export const CreateCertificateDocument = `
    mutation CreateCertificate($input: CreateCertificateInput!) {
  createCertificate(input: $input) {
    certificate {
      ...CertificateDetails
    }
  }
}
    ${CertificateDetailsFragmentDoc}`;
export const useCreateCertificateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateCertificateMutation, TError, Types.CreateCertificateMutationVariables, TContext>) =>
    useMutation<Types.CreateCertificateMutation, TError, Types.CreateCertificateMutationVariables, TContext>(
      ['CreateCertificate'],
      useFetcher<Types.CreateCertificateMutation, Types.CreateCertificateMutationVariables>(CreateCertificateDocument),
      options
    );
export const DeleteCertificateDocument = `
    mutation DeleteCertificate($input: DeleteCertificateInput!) {
  deleteCertificate(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteCertificateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteCertificateMutation, TError, Types.DeleteCertificateMutationVariables, TContext>) =>
    useMutation<Types.DeleteCertificateMutation, TError, Types.DeleteCertificateMutationVariables, TContext>(
      ['DeleteCertificate'],
      useFetcher<Types.DeleteCertificateMutation, Types.DeleteCertificateMutationVariables>(DeleteCertificateDocument),
      options
    );
export const UpdateCertificateDocument = `
    mutation UpdateCertificate($input: UpdateCertificateInput!) {
  updateCertificate(input: $input) {
    certificate {
      ...CertificateDetails
    }
  }
}
    ${CertificateDetailsFragmentDoc}`;
export const useUpdateCertificateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCertificateMutation, TError, Types.UpdateCertificateMutationVariables, TContext>) =>
    useMutation<Types.UpdateCertificateMutation, TError, Types.UpdateCertificateMutationVariables, TContext>(
      ['UpdateCertificate'],
      useFetcher<Types.UpdateCertificateMutation, Types.UpdateCertificateMutationVariables>(UpdateCertificateDocument),
      options
    );
export const GetDataForCommitListDocument = `
    query GetDataForCommitList($appId: String!, $search: String, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $appId) {
    id
    name
    nativeType
    organization {
      id
      name
      slug
      buildCreditsAvailable
    }
    commits(
      first: $first
      search: $search
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ...CommitListDetails
        }
      }
    }
  }
}
    ${CommitListDetailsFragmentDoc}`;
export const useGetDataForCommitListQuery = <
      TData = Types.GetDataForCommitListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForCommitListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForCommitListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForCommitListQuery, TError, TData>(
      ['GetDataForCommitList', variables],
      useFetcher<Types.GetDataForCommitListQuery, Types.GetDataForCommitListQueryVariables>(GetDataForCommitListDocument).bind(null, variables),
      options
    );
export const InitiateGitLinkForGitHostListItemDocument = `
    mutation InitiateGitLinkForGitHostListItem($gitType: RepositoryType!, $baseUrl: String, $accessToken: String) {
  initiateGitLink(
    input: {gitType: $gitType, baseUrl: $baseUrl, accessToken: $accessToken}
  ) {
    authorizationUrl
  }
}
    `;
export const useInitiateGitLinkForGitHostListItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.InitiateGitLinkForGitHostListItemMutation, TError, Types.InitiateGitLinkForGitHostListItemMutationVariables, TContext>) =>
    useMutation<Types.InitiateGitLinkForGitHostListItemMutation, TError, Types.InitiateGitLinkForGitHostListItemMutationVariables, TContext>(
      ['InitiateGitLinkForGitHostListItem'],
      useFetcher<Types.InitiateGitLinkForGitHostListItemMutation, Types.InitiateGitLinkForGitHostListItemMutationVariables>(InitiateGitLinkForGitHostListItemDocument),
      options
    );
export const GetDataForAppImportDocument = `
    query GetDataForAppImport($slug: String!) {
  organization(slug: $slug) {
    id
  }
}
    `;
export const useGetDataForAppImportQuery = <
      TData = Types.GetDataForAppImportQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppImportQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppImportQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppImportQuery, TError, TData>(
      ['GetDataForAppImport', variables],
      useFetcher<Types.GetDataForAppImportQuery, Types.GetDataForAppImportQueryVariables>(GetDataForAppImportDocument).bind(null, variables),
      options
    );
export const GetDataForCreateWizardAppDocument = `
    query GetDataForCreateWizardApp($slug: String!) {
  organization(slug: $slug) {
    id
  }
}
    `;
export const useGetDataForCreateWizardAppQuery = <
      TData = Types.GetDataForCreateWizardAppQuery,
      TError = unknown
    >(
      variables: Types.GetDataForCreateWizardAppQueryVariables,
      options?: UseQueryOptions<Types.GetDataForCreateWizardAppQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForCreateWizardAppQuery, TError, TData>(
      ['GetDataForCreateWizardApp', variables],
      useFetcher<Types.GetDataForCreateWizardAppQuery, Types.GetDataForCreateWizardAppQueryVariables>(GetDataForCreateWizardAppDocument).bind(null, variables),
      options
    );
export const GetDataForBuildListChooseDocument = `
    query GetDataForBuildListChoose($appId: String!) {
  app(id: $appId) {
    nativeType
  }
}
    `;
export const useGetDataForBuildListChooseQuery = <
      TData = Types.GetDataForBuildListChooseQuery,
      TError = unknown
    >(
      variables: Types.GetDataForBuildListChooseQueryVariables,
      options?: UseQueryOptions<Types.GetDataForBuildListChooseQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForBuildListChooseQuery, TError, TData>(
      ['GetDataForBuildListChoose', variables],
      useFetcher<Types.GetDataForBuildListChooseQuery, Types.GetDataForBuildListChooseQueryVariables>(GetDataForBuildListChooseDocument).bind(null, variables),
      options
    );
export const GetDataForChannelMultiselectDocument = `
    query GetDataForChannelMultiselect($id: String!) {
  app(id: $id) {
    id
    nativeType
  }
}
    `;
export const useGetDataForChannelMultiselectQuery = <
      TData = Types.GetDataForChannelMultiselectQuery,
      TError = unknown
    >(
      variables: Types.GetDataForChannelMultiselectQueryVariables,
      options?: UseQueryOptions<Types.GetDataForChannelMultiselectQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForChannelMultiselectQuery, TError, TData>(
      ['GetDataForChannelMultiselect', variables],
      useFetcher<Types.GetDataForChannelMultiselectQuery, Types.GetDataForChannelMultiselectQueryVariables>(GetDataForChannelMultiselectDocument).bind(null, variables),
      options
    );
export const GetDataForDeploymentCompileDocument = `
    query GetDataForDeploymentCompile($id: String!) {
  app(id: $id) {
    id
    nativeType
    channels {
      edges {
        node {
          id
          name
        }
      }
    }
    organization {
      id
    }
  }
}
    `;
export const useGetDataForDeploymentCompileQuery = <
      TData = Types.GetDataForDeploymentCompileQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDeploymentCompileQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDeploymentCompileQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDeploymentCompileQuery, TError, TData>(
      ['GetDataForDeploymentCompile', variables],
      useFetcher<Types.GetDataForDeploymentCompileQuery, Types.GetDataForDeploymentCompileQueryVariables>(GetDataForDeploymentCompileDocument).bind(null, variables),
      options
    );
export const CreateDistributionBuildDocument = `
    mutation CreateDistributionBuild($input: CreateDistributionBuildInput!) {
  createDistributionBuild(input: $input) {
    build {
      jobId
      distributionCredential {
        name
      }
    }
  }
}
    `;
export const useCreateDistributionBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateDistributionBuildMutation, TError, Types.CreateDistributionBuildMutationVariables, TContext>) =>
    useMutation<Types.CreateDistributionBuildMutation, TError, Types.CreateDistributionBuildMutationVariables, TContext>(
      ['CreateDistributionBuild'],
      useFetcher<Types.CreateDistributionBuildMutation, Types.CreateDistributionBuildMutationVariables>(CreateDistributionBuildDocument),
      options
    );
export const GetDataForDestinationCredentialDropdownDocument = `
    query GetDataForDestinationCredentialDropdown($id: String!) {
  app(id: $id) {
    id
    nativeType
  }
}
    `;
export const useGetDataForDestinationCredentialDropdownQuery = <
      TData = Types.GetDataForDestinationCredentialDropdownQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDestinationCredentialDropdownQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDestinationCredentialDropdownQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDestinationCredentialDropdownQuery, TError, TData>(
      ['GetDataForDestinationCredentialDropdown', variables],
      useFetcher<Types.GetDataForDestinationCredentialDropdownQuery, Types.GetDataForDestinationCredentialDropdownQueryVariables>(GetDataForDestinationCredentialDropdownDocument).bind(null, variables),
      options
    );
export const GetDataForDeploymentListDocument = `
    query GetDataForDeploymentList($appId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $appId) {
    id
    name
    organization {
      name
      slug
    }
    deployments(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...DeploymentListFields
          ...BinaryDeploymentListFields
          ...LiveUpdateDeploymentListFields
        }
      }
    }
  }
}
    ${DeploymentListFieldsFragmentDoc}
${BinaryDeploymentListFieldsFragmentDoc}
${LiveUpdateDeploymentListFieldsFragmentDoc}`;
export const useGetDataForDeploymentListQuery = <
      TData = Types.GetDataForDeploymentListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDeploymentListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDeploymentListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDeploymentListQuery, TError, TData>(
      ['GetDataForDeploymentList', variables],
      useFetcher<Types.GetDataForDeploymentListQuery, Types.GetDataForDeploymentListQueryVariables>(GetDataForDeploymentListDocument).bind(null, variables),
      options
    );
export const CreateChannelDocument = `
    mutation CreateChannel($appId: String!, $channelName: String!) {
  createChannel(input: {appId: $appId, name: $channelName}) {
    clientMutationId
  }
}
    `;
export const useCreateChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateChannelMutation, TError, Types.CreateChannelMutationVariables, TContext>) =>
    useMutation<Types.CreateChannelMutation, TError, Types.CreateChannelMutationVariables, TContext>(
      ['CreateChannel'],
      useFetcher<Types.CreateChannelMutation, Types.CreateChannelMutationVariables>(CreateChannelDocument),
      options
    );
export const DeleteChannelDocument = `
    mutation DeleteChannel($appId: String!, $channelName: String!) {
  deleteChannel(input: {appId: $appId, name: $channelName}) {
    success
  }
}
    `;
export const useDeleteChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteChannelMutation, TError, Types.DeleteChannelMutationVariables, TContext>) =>
    useMutation<Types.DeleteChannelMutation, TError, Types.DeleteChannelMutationVariables, TContext>(
      ['DeleteChannel'],
      useFetcher<Types.DeleteChannelMutation, Types.DeleteChannelMutationVariables>(DeleteChannelDocument),
      options
    );
export const DeleteDistributionCredentialDocument = `
    mutation DeleteDistributionCredential($appId: String!, $distributionCredentialId: Int!) {
  deleteDistCredential(
    input: {appId: $appId, distributionCredentialId: $distributionCredentialId}
  ) {
    __typename
  }
}
    `;
export const useDeleteDistributionCredentialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteDistributionCredentialMutation, TError, Types.DeleteDistributionCredentialMutationVariables, TContext>) =>
    useMutation<Types.DeleteDistributionCredentialMutation, TError, Types.DeleteDistributionCredentialMutationVariables, TContext>(
      ['DeleteDistributionCredential'],
      useFetcher<Types.DeleteDistributionCredentialMutation, Types.DeleteDistributionCredentialMutationVariables>(DeleteDistributionCredentialDocument),
      options
    );
export const GetDataForDestinationDetailDocument = `
    query GetDataForDestinationDetail($id: String!, $destinationId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $id) {
    id
    nativeType
    destination(destinationId: $destinationId) {
      ... on Channel {
        channelId: id
        name
        __typename
        deployments(first: $first, last: $last, after: $after, before: $before) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              ...LiveUpdateDeploymentDetails
            }
          }
        }
      }
      ... on ItunesConnectDistributionCredential {
        distributionCredentialId: id
        __typename
        type
        artifactUploadType
        name
        appleAppId
        teamName
        teamId
        userName
        deployments(first: $first, last: $last, after: $after, before: $before) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              ...BinaryDeploymentDetails
            }
          }
        }
      }
      ... on GooglePlayDistCredential {
        distributionCredentialId: id
        type
        __typename
        artifactUploadType
        name
        releaseStatus
        packageName
        track
        deployments(first: $first, last: $last, after: $after, before: $before) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              ...BinaryDeploymentDetails
            }
          }
        }
      }
    }
  }
}
    ${LiveUpdateDeploymentDetailsFragmentDoc}
${BinaryDeploymentDetailsFragmentDoc}`;
export const useGetDataForDestinationDetailQuery = <
      TData = Types.GetDataForDestinationDetailQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDestinationDetailQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDestinationDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDestinationDetailQuery, TError, TData>(
      ['GetDataForDestinationDetail', variables],
      useFetcher<Types.GetDataForDestinationDetailQuery, Types.GetDataForDestinationDetailQueryVariables>(GetDataForDestinationDetailDocument).bind(null, variables),
      options
    );
export const UpdateChannelDocument = `
    mutation UpdateChannel($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    channel {
      name
      build {
        jobId
      }
    }
  }
}
    `;
export const useUpdateChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateChannelMutation, TError, Types.UpdateChannelMutationVariables, TContext>) =>
    useMutation<Types.UpdateChannelMutation, TError, Types.UpdateChannelMutationVariables, TContext>(
      ['UpdateChannel'],
      useFetcher<Types.UpdateChannelMutation, Types.UpdateChannelMutationVariables>(UpdateChannelDocument),
      options
    );
export const GetDataForLiveUpdateDestinationListDocument = `
    query GetDataForLiveUpdateDestinationList($id: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $id) {
    id
    name
    nativeType
    organization {
      id
      name
      slug
    }
    channels(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          __typename
          ...LiveUpdateDestinationListChannel
        }
      }
    }
  }
}
    ${LiveUpdateDestinationListChannelFragmentDoc}`;
export const useGetDataForLiveUpdateDestinationListQuery = <
      TData = Types.GetDataForLiveUpdateDestinationListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForLiveUpdateDestinationListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForLiveUpdateDestinationListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForLiveUpdateDestinationListQuery, TError, TData>(
      ['GetDataForLiveUpdateDestinationList', variables],
      useFetcher<Types.GetDataForLiveUpdateDestinationListQuery, Types.GetDataForLiveUpdateDestinationListQueryVariables>(GetDataForLiveUpdateDestinationListDocument).bind(null, variables),
      options
    );
export const GetDataForStoreDestinationListDocument = `
    query GetDataForStoreDestinationList($id: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $id) {
    id
    name
    nativeType
    organization {
      id
      name
      slug
    }
    distributionCredentials(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ... on GooglePlayDistCredential {
            __typename
            ...StoreDestinationListGooglePlayCredential
          }
          ... on ItunesConnectDistributionCredential {
            __typename
            ...StoreDestinationListItunesCredential
          }
        }
      }
    }
  }
}
    ${StoreDestinationListGooglePlayCredentialFragmentDoc}
${StoreDestinationListItunesCredentialFragmentDoc}`;
export const useGetDataForStoreDestinationListQuery = <
      TData = Types.GetDataForStoreDestinationListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForStoreDestinationListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForStoreDestinationListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForStoreDestinationListQuery, TError, TData>(
      ['GetDataForStoreDestinationList', variables],
      useFetcher<Types.GetDataForStoreDestinationListQuery, Types.GetDataForStoreDestinationListQueryVariables>(GetDataForStoreDestinationListDocument).bind(null, variables),
      options
    );
export const GetDataForE2ETestCreateWizardDocument = `
    query GetDataForE2ETestCreateWizard($appId: String!) {
  app(id: $appId) {
    id
    environments {
      edges {
        node {
          environmentId
          name
        }
      }
    }
    organization {
      id
    }
  }
}
    `;
export const useGetDataForE2ETestCreateWizardQuery = <
      TData = Types.GetDataForE2ETestCreateWizardQuery,
      TError = unknown
    >(
      variables: Types.GetDataForE2ETestCreateWizardQueryVariables,
      options?: UseQueryOptions<Types.GetDataForE2ETestCreateWizardQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForE2ETestCreateWizardQuery, TError, TData>(
      ['GetDataForE2ETestCreateWizard', variables],
      useFetcher<Types.GetDataForE2ETestCreateWizardQuery, Types.GetDataForE2ETestCreateWizardQueryVariables>(GetDataForE2ETestCreateWizardDocument).bind(null, variables),
      options
    );
export const GetAppForE2ETestDetailDocument = `
    query GetAppForE2ETestDetail($appId: String!) {
  app(id: $appId) {
    id
    name
    organization {
      name
      slug
    }
  }
}
    `;
export const useGetAppForE2ETestDetailQuery = <
      TData = Types.GetAppForE2ETestDetailQuery,
      TError = unknown
    >(
      variables: Types.GetAppForE2ETestDetailQueryVariables,
      options?: UseQueryOptions<Types.GetAppForE2ETestDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetAppForE2ETestDetailQuery, TError, TData>(
      ['GetAppForE2ETestDetail', variables],
      useFetcher<Types.GetAppForE2ETestDetailQuery, Types.GetAppForE2ETestDetailQueryVariables>(GetAppForE2ETestDetailDocument).bind(null, variables),
      options
    );
export const GetDataForE2ERunTestsDocument = `
    query GetDataForE2ERunTests($appId: String!) {
  app(id: $appId) {
    id
    name
    organization {
      id
      name
      slug
    }
  }
}
    `;
export const useGetDataForE2ERunTestsQuery = <
      TData = Types.GetDataForE2ERunTestsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForE2ERunTestsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForE2ERunTestsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForE2ERunTestsQuery, TError, TData>(
      ['GetDataForE2ERunTests', variables],
      useFetcher<Types.GetDataForE2ERunTestsQuery, Types.GetDataForE2ERunTestsQueryVariables>(GetDataForE2ERunTestsDocument).bind(null, variables),
      options
    );
export const GetDataForE2ETestRunsDocument = `
    query GetDataForE2ETestRuns($appId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $appId) {
    id
    name
    organization {
      id
      name
      slug
    }
    e2eTestRuns(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      edges {
        node {
          id
          runNumber
          created
          label
          caller {
            name
            picture
          }
          app {
            id
          }
          started
          finished
          duration
          platforms
          state
          commit {
            id
            beforeSha
            created
            commitRepoLink
            note
            ref
            refType
            sha
            shortSha
            uuid
            repository {
              id
              cloneUrl
              fullName
              htmlUrl
              type
            }
            user {
              name
              username
              picture
            }
          }
          tests {
            ...E2ETestListFields
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${E2ETestListFieldsFragmentDoc}`;
export const useGetDataForE2ETestRunsQuery = <
      TData = Types.GetDataForE2ETestRunsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForE2ETestRunsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForE2ETestRunsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForE2ETestRunsQuery, TError, TData>(
      ['GetDataForE2ETestRuns', variables],
      useFetcher<Types.GetDataForE2ETestRunsQuery, Types.GetDataForE2ETestRunsQueryVariables>(GetDataForE2ETestRunsDocument).bind(null, variables),
      options
    );
export const GetDataForEnterpriseSdkTrialActivatedCtaDocument = `
    query GetDataForEnterpriseSdkTrialActivatedCta($appId: String!) {
  app(id: $appId) {
    organization {
      slug
    }
  }
}
    `;
export const useGetDataForEnterpriseSdkTrialActivatedCtaQuery = <
      TData = Types.GetDataForEnterpriseSdkTrialActivatedCtaQuery,
      TError = unknown
    >(
      variables: Types.GetDataForEnterpriseSdkTrialActivatedCtaQueryVariables,
      options?: UseQueryOptions<Types.GetDataForEnterpriseSdkTrialActivatedCtaQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForEnterpriseSdkTrialActivatedCtaQuery, TError, TData>(
      ['GetDataForEnterpriseSdkTrialActivatedCta', variables],
      useFetcher<Types.GetDataForEnterpriseSdkTrialActivatedCtaQuery, Types.GetDataForEnterpriseSdkTrialActivatedCtaQueryVariables>(GetDataForEnterpriseSdkTrialActivatedCtaDocument).bind(null, variables),
      options
    );
export const InitiateGitLinkForConnectGitOriginDocument = `
    mutation InitiateGitLinkForConnectGitOrigin($gitType: RepositoryType!, $baseUrl: String, $accessToken: String) {
  initiateGitLink(
    input: {gitType: $gitType, baseUrl: $baseUrl, accessToken: $accessToken}
  ) {
    authorizationUrl
  }
}
    `;
export const useInitiateGitLinkForConnectGitOriginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.InitiateGitLinkForConnectGitOriginMutation, TError, Types.InitiateGitLinkForConnectGitOriginMutationVariables, TContext>) =>
    useMutation<Types.InitiateGitLinkForConnectGitOriginMutation, TError, Types.InitiateGitLinkForConnectGitOriginMutationVariables, TContext>(
      ['InitiateGitLinkForConnectGitOrigin'],
      useFetcher<Types.InitiateGitLinkForConnectGitOriginMutation, Types.InitiateGitLinkForConnectGitOriginMutationVariables>(InitiateGitLinkForConnectGitOriginDocument),
      options
    );
export const GetDataForAppBuildSettingsDocument = `
    query GetDataForAppBuildSettings($id: String!) {
  app(id: $id) {
    ...AppBuildSettingsApp
  }
}
    ${AppBuildSettingsAppFragmentDoc}`;
export const useGetDataForAppBuildSettingsQuery = <
      TData = Types.GetDataForAppBuildSettingsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppBuildSettingsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppBuildSettingsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppBuildSettingsQuery, TError, TData>(
      ['GetDataForAppBuildSettings', variables],
      useFetcher<Types.GetDataForAppBuildSettingsQuery, Types.GetDataForAppBuildSettingsQueryVariables>(GetDataForAppBuildSettingsDocument).bind(null, variables),
      options
    );
export const GetAppBuildCachesDocument = `
    query GetAppBuildCaches($id: String!, $first: Int, $last: Int, $after: String, $before: String) {
  app(id: $id) {
    caches(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...AppBuildCache
        }
      }
    }
  }
}
    ${AppBuildCacheFragmentDoc}`;
export const useGetAppBuildCachesQuery = <
      TData = Types.GetAppBuildCachesQuery,
      TError = unknown
    >(
      variables: Types.GetAppBuildCachesQueryVariables,
      options?: UseQueryOptions<Types.GetAppBuildCachesQuery, TError, TData>
    ) =>
    useQuery<Types.GetAppBuildCachesQuery, TError, TData>(
      ['GetAppBuildCaches', variables],
      useFetcher<Types.GetAppBuildCachesQuery, Types.GetAppBuildCachesQueryVariables>(GetAppBuildCachesDocument).bind(null, variables),
      options
    );
export const EnableWebPreviewOnAppFromSettingsDocument = `
    mutation EnableWebPreviewOnAppFromSettings($appId: String!, $webPreview: Boolean!) {
  updateApp(input: {appId: $appId, webPreview: $webPreview}) {
    app {
      id
    }
  }
}
    `;
export const useEnableWebPreviewOnAppFromSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.EnableWebPreviewOnAppFromSettingsMutation, TError, Types.EnableWebPreviewOnAppFromSettingsMutationVariables, TContext>) =>
    useMutation<Types.EnableWebPreviewOnAppFromSettingsMutation, TError, Types.EnableWebPreviewOnAppFromSettingsMutationVariables, TContext>(
      ['EnableWebPreviewOnAppFromSettings'],
      useFetcher<Types.EnableWebPreviewOnAppFromSettingsMutation, Types.EnableWebPreviewOnAppFromSettingsMutationVariables>(EnableWebPreviewOnAppFromSettingsDocument),
      options
    );
export const EnableCachingOnAppFromSettingsDocument = `
    mutation EnableCachingOnAppFromSettings($appId: String!, $dependencyCaching: Boolean!) {
  updateApp(input: {appId: $appId, dependencyCaching: $dependencyCaching}) {
    app {
      id
    }
  }
}
    `;
export const useEnableCachingOnAppFromSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.EnableCachingOnAppFromSettingsMutation, TError, Types.EnableCachingOnAppFromSettingsMutationVariables, TContext>) =>
    useMutation<Types.EnableCachingOnAppFromSettingsMutation, TError, Types.EnableCachingOnAppFromSettingsMutationVariables, TContext>(
      ['EnableCachingOnAppFromSettings'],
      useFetcher<Types.EnableCachingOnAppFromSettingsMutation, Types.EnableCachingOnAppFromSettingsMutationVariables>(EnableCachingOnAppFromSettingsDocument),
      options
    );
export const DeleteAppBuildCachesDocument = `
    mutation DeleteAppBuildCaches($appId: String!, $cacheKeys: [String]) {
  deleteAppBuildCaches(input: {appId: $appId, cacheKeys: $cacheKeys}) {
    clientMutationId
  }
}
    `;
export const useDeleteAppBuildCachesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteAppBuildCachesMutation, TError, Types.DeleteAppBuildCachesMutationVariables, TContext>) =>
    useMutation<Types.DeleteAppBuildCachesMutation, TError, Types.DeleteAppBuildCachesMutationVariables, TContext>(
      ['DeleteAppBuildCaches'],
      useFetcher<Types.DeleteAppBuildCachesMutation, Types.DeleteAppBuildCachesMutationVariables>(DeleteAppBuildCachesDocument),
      options
    );
export const GetDataForAppSettingsDocument = `
    query GetDataForAppSettings($id: String!) {
  app(id: $id) {
    id
    icon
    name
    nativeType
    lastActivity
    owner {
      username
    }
    organization {
      name
      slug
    }
    association {
      repository {
        fullName
      }
    }
  }
}
    `;
export const useGetDataForAppSettingsQuery = <
      TData = Types.GetDataForAppSettingsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppSettingsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppSettingsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppSettingsQuery, TError, TData>(
      ['GetDataForAppSettings', variables],
      useFetcher<Types.GetDataForAppSettingsQuery, Types.GetDataForAppSettingsQueryVariables>(GetDataForAppSettingsDocument).bind(null, variables),
      options
    );
export const TransferAppDocument = `
    mutation TransferApp($appId: String!, $orgId: String!) {
  transferApp(input: {appId: $appId, orgId: $orgId}) {
    app {
      id
    }
  }
}
    `;
export const useTransferAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.TransferAppMutation, TError, Types.TransferAppMutationVariables, TContext>) =>
    useMutation<Types.TransferAppMutation, TError, Types.TransferAppMutationVariables, TContext>(
      ['TransferApp'],
      useFetcher<Types.TransferAppMutation, Types.TransferAppMutationVariables>(TransferAppDocument),
      options
    );
export const GetDataForAppSettingsGitDocument = `
    query GetDataForAppSettingsGit($appId: String!) {
  app(id: $appId) {
    ...GitSettingsApp
  }
}
    ${GitSettingsAppFragmentDoc}`;
export const useGetDataForAppSettingsGitQuery = <
      TData = Types.GetDataForAppSettingsGitQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppSettingsGitQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppSettingsGitQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppSettingsGitQuery, TError, TData>(
      ['GetDataForAppSettingsGit', variables],
      useFetcher<Types.GetDataForAppSettingsGitQuery, Types.GetDataForAppSettingsGitQueryVariables>(GetDataForAppSettingsGitDocument).bind(null, variables),
      options
    );
export const GetDataForAppDetailDocument = `
    query GetDataForAppDetail($appId: String!) {
  app(id: $appId) {
    id
    icon
    name
    nativeType
    organization {
      id
      name
      plan
      slug
    }
  }
  viewer {
    email
    name
    userId
  }
}
    `;
export const useGetDataForAppDetailQuery = <
      TData = Types.GetDataForAppDetailQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppDetailQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppDetailQuery, TError, TData>(
      ['GetDataForAppDetail', variables],
      useFetcher<Types.GetDataForAppDetailQuery, Types.GetDataForAppDetailQueryVariables>(GetDataForAppDetailDocument).bind(null, variables),
      options
    );
export const GetDataForDownloadsDocument = `
    query GetDataForDownloads($slug: String!) {
  organization(slug: $slug) {
    id
    slug
    studioSeatsPurchased
  }
  viewer {
    hasStudio
  }
}
    `;
export const useGetDataForDownloadsQuery = <
      TData = Types.GetDataForDownloadsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForDownloadsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForDownloadsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForDownloadsQuery, TError, TData>(
      ['GetDataForDownloads', variables],
      useFetcher<Types.GetDataForDownloadsQuery, Types.GetDataForDownloadsQueryVariables>(GetDataForDownloadsDocument).bind(null, variables),
      options
    );
export const GetDataForAssignToAppWarningDocument = `
    query GetDataForAssignToAppWarning($orgSlug: String!) {
  organization(slug: $orgSlug) {
    apps {
      edges {
        node {
          id
          name
          nativeType
          icon
        }
      }
    }
  }
}
    `;
export const useGetDataForAssignToAppWarningQuery = <
      TData = Types.GetDataForAssignToAppWarningQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAssignToAppWarningQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAssignToAppWarningQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAssignToAppWarningQuery, TError, TData>(
      ['GetDataForAssignToAppWarning', variables],
      useFetcher<Types.GetDataForAssignToAppWarningQuery, Types.GetDataForAssignToAppWarningQueryVariables>(GetDataForAssignToAppWarningDocument).bind(null, variables),
      options
    );
export const UpdateNativeKeyAssociationDocument = `
    mutation updateNativeKeyAssociation($orgId: String!, $appId: String!, $keyId: Int!) {
  updateOrgNativeKeyAssociation(
    input: {orgId: $orgId, appId: $appId, keyId: $keyId}
  ) {
    success
  }
}
    `;
export const useUpdateNativeKeyAssociationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateNativeKeyAssociationMutation, TError, Types.UpdateNativeKeyAssociationMutationVariables, TContext>) =>
    useMutation<Types.UpdateNativeKeyAssociationMutation, TError, Types.UpdateNativeKeyAssociationMutationVariables, TContext>(
      ['updateNativeKeyAssociation'],
      useFetcher<Types.UpdateNativeKeyAssociationMutation, Types.UpdateNativeKeyAssociationMutationVariables>(UpdateNativeKeyAssociationDocument),
      options
    );
export const AcceptInviteDocument = `
    mutation AcceptInvite($input: AcceptInviteInput!) {
  acceptInvite(input: $input) {
    clientMutationId
  }
}
    `;
export const useAcceptInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AcceptInviteMutation, TError, Types.AcceptInviteMutationVariables, TContext>) =>
    useMutation<Types.AcceptInviteMutation, TError, Types.AcceptInviteMutationVariables, TContext>(
      ['AcceptInvite'],
      useFetcher<Types.AcceptInviteMutation, Types.AcceptInviteMutationVariables>(AcceptInviteDocument),
      options
    );
export const GetInviteDocument = `
    query GetInvite($inviteId: String!) {
  invite(inviteId: $inviteId) {
    id
    email
    senderName
    organization {
      avatar
      name
      slug
    }
  }
}
    `;
export const useGetInviteQuery = <
      TData = Types.GetInviteQuery,
      TError = unknown
    >(
      variables: Types.GetInviteQueryVariables,
      options?: UseQueryOptions<Types.GetInviteQuery, TError, TData>
    ) =>
    useQuery<Types.GetInviteQuery, TError, TData>(
      ['GetInvite', variables],
      useFetcher<Types.GetInviteQuery, Types.GetInviteQueryVariables>(GetInviteDocument).bind(null, variables),
      options
    );
export const GetOrgAddMembersModalDocument = `
    query GetOrgAddMembersModal($orgId: String) {
  organization(id: $orgId) {
    teams {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;
export const useGetOrgAddMembersModalQuery = <
      TData = Types.GetOrgAddMembersModalQuery,
      TError = unknown
    >(
      variables?: Types.GetOrgAddMembersModalQueryVariables,
      options?: UseQueryOptions<Types.GetOrgAddMembersModalQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgAddMembersModalQuery, TError, TData>(
      variables === undefined ? ['GetOrgAddMembersModal'] : ['GetOrgAddMembersModal', variables],
      useFetcher<Types.GetOrgAddMembersModalQuery, Types.GetOrgAddMembersModalQueryVariables>(GetOrgAddMembersModalDocument).bind(null, variables),
      options
    );
export const InviteUserToOrgDocument = `
    mutation InviteUserToOrg($input: InviteUserToOrgInput!) {
  inviteUserToOrg(input: $input) {
    invites {
      organization {
        id
        name
      }
      email
    }
  }
}
    `;
export const useInviteUserToOrgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.InviteUserToOrgMutation, TError, Types.InviteUserToOrgMutationVariables, TContext>) =>
    useMutation<Types.InviteUserToOrgMutation, TError, Types.InviteUserToOrgMutationVariables, TContext>(
      ['InviteUserToOrg'],
      useFetcher<Types.InviteUserToOrgMutation, Types.InviteUserToOrgMutationVariables>(InviteUserToOrgDocument),
      options
    );
export const UpdateMemberRoleDocument = `
    mutation UpdateMemberRole($input: UpdateOrgUsersInput!) {
  updateOrgUsers(input: $input) {
    clientMutationId
  }
}
    `;
export const useUpdateMemberRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateMemberRoleMutation, TError, Types.UpdateMemberRoleMutationVariables, TContext>) =>
    useMutation<Types.UpdateMemberRoleMutation, TError, Types.UpdateMemberRoleMutationVariables, TContext>(
      ['UpdateMemberRole'],
      useFetcher<Types.UpdateMemberRoleMutation, Types.UpdateMemberRoleMutationVariables>(UpdateMemberRoleDocument),
      options
    );
export const GetOrgMembersInvitedDocument = `
    query GetOrgMembersInvited($slug: String!) {
  organization(slug: $slug) {
    id
    availableSeats
    name
    slug
    availableSeats
    invites {
      edges {
        node {
          id
          email
          created
          senderName
        }
      }
    }
    members {
      edges {
        node {
          name
          picture
          userId
        }
      }
    }
    teams {
      edges {
        node {
          id
          color
          description
          name
          users {
            totalCount
          }
        }
      }
    }
  }
}
    `;
export const useGetOrgMembersInvitedQuery = <
      TData = Types.GetOrgMembersInvitedQuery,
      TError = unknown
    >(
      variables: Types.GetOrgMembersInvitedQueryVariables,
      options?: UseQueryOptions<Types.GetOrgMembersInvitedQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgMembersInvitedQuery, TError, TData>(
      ['GetOrgMembersInvited', variables],
      useFetcher<Types.GetOrgMembersInvitedQuery, Types.GetOrgMembersInvitedQueryVariables>(GetOrgMembersInvitedDocument).bind(null, variables),
      options
    );
export const DeleteOrgInviteDocument = `
    mutation DeleteOrgInvite($input: DeleteOrgInviteInput!) {
  deleteOrgInvite(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteOrgInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteOrgInviteMutation, TError, Types.DeleteOrgInviteMutationVariables, TContext>) =>
    useMutation<Types.DeleteOrgInviteMutation, TError, Types.DeleteOrgInviteMutationVariables, TContext>(
      ['DeleteOrgInvite'],
      useFetcher<Types.DeleteOrgInviteMutation, Types.DeleteOrgInviteMutationVariables>(DeleteOrgInviteDocument),
      options
    );
export const ResendOrgInviteDocument = `
    mutation ResendOrgInvite($input: ResendOrgInviteInput!) {
  resendOrgInvite(input: $input) {
    clientMutationId
  }
}
    `;
export const useResendOrgInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ResendOrgInviteMutation, TError, Types.ResendOrgInviteMutationVariables, TContext>) =>
    useMutation<Types.ResendOrgInviteMutation, TError, Types.ResendOrgInviteMutationVariables, TContext>(
      ['ResendOrgInvite'],
      useFetcher<Types.ResendOrgInviteMutation, Types.ResendOrgInviteMutationVariables>(ResendOrgInviteDocument),
      options
    );
export const GetDataForOrgMembersListDocument = `
    query GetDataForOrgMembersList($slug: String!) {
  organization(slug: $slug) {
    id
    availableSeats
    name
    slug
    availableSeats
    teams {
      edges {
        node {
          ...OrgMembersTeam
        }
      }
    }
    members {
      edges {
        node {
          ...MemberDetail
        }
      }
    }
  }
}
    ${OrgMembersTeamFragmentDoc}
${MemberDetailFragmentDoc}`;
export const useGetDataForOrgMembersListQuery = <
      TData = Types.GetDataForOrgMembersListQuery,
      TError = unknown
    >(
      variables: Types.GetDataForOrgMembersListQueryVariables,
      options?: UseQueryOptions<Types.GetDataForOrgMembersListQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForOrgMembersListQuery, TError, TData>(
      ['GetDataForOrgMembersList', variables],
      useFetcher<Types.GetDataForOrgMembersListQuery, Types.GetDataForOrgMembersListQueryVariables>(GetDataForOrgMembersListDocument).bind(null, variables),
      options
    );
export const RemoveOrgMembersDocument = `
    mutation RemoveOrgMembers($input: RemoveOrgUsersInput!) {
  removeOrgUsers(input: $input) {
    clientMutationId
  }
}
    `;
export const useRemoveOrgMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveOrgMembersMutation, TError, Types.RemoveOrgMembersMutationVariables, TContext>) =>
    useMutation<Types.RemoveOrgMembersMutation, TError, Types.RemoveOrgMembersMutationVariables, TContext>(
      ['RemoveOrgMembers'],
      useFetcher<Types.RemoveOrgMembersMutation, Types.RemoveOrgMembersMutationVariables>(RemoveOrgMembersDocument),
      options
    );
export const GetDataForOrgAccountSettingsDocument = `
    query GetDataForOrgAccountSettings($slug: String!) {
  organization(slug: $slug) {
    id
    avatar
    description
    email
    name
  }
}
    `;
export const useGetDataForOrgAccountSettingsQuery = <
      TData = Types.GetDataForOrgAccountSettingsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForOrgAccountSettingsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForOrgAccountSettingsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForOrgAccountSettingsQuery, TError, TData>(
      ['GetDataForOrgAccountSettings', variables],
      useFetcher<Types.GetDataForOrgAccountSettingsQuery, Types.GetDataForOrgAccountSettingsQueryVariables>(GetDataForOrgAccountSettingsDocument).bind(null, variables),
      options
    );
export const GetDataForOrgSettingsDocument = `
    query GetDataForOrgSettings($slug: String!) {
  organization(slug: $slug) {
    id
    slug
    name
    email
    hasNativeAccess
    plan
  }
}
    `;
export const useGetDataForOrgSettingsQuery = <
      TData = Types.GetDataForOrgSettingsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForOrgSettingsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForOrgSettingsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForOrgSettingsQuery, TError, TData>(
      ['GetDataForOrgSettings', variables],
      useFetcher<Types.GetDataForOrgSettingsQuery, Types.GetDataForOrgSettingsQueryVariables>(GetDataForOrgSettingsDocument).bind(null, variables),
      options
    );
export const GetOrgMembersForNewTeamModalDocument = `
    query GetOrgMembersForNewTeamModal($orgId: String!) {
  organization(id: $orgId) {
    id
    users {
      edges {
        node {
          id
          userId
          email
          name
          picture
          avatar
        }
      }
    }
  }
}
    `;
export const useGetOrgMembersForNewTeamModalQuery = <
      TData = Types.GetOrgMembersForNewTeamModalQuery,
      TError = unknown
    >(
      variables: Types.GetOrgMembersForNewTeamModalQueryVariables,
      options?: UseQueryOptions<Types.GetOrgMembersForNewTeamModalQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgMembersForNewTeamModalQuery, TError, TData>(
      ['GetOrgMembersForNewTeamModal', variables],
      useFetcher<Types.GetOrgMembersForNewTeamModalQuery, Types.GetOrgMembersForNewTeamModalQueryVariables>(GetOrgMembersForNewTeamModalDocument).bind(null, variables),
      options
    );
export const CreateTeamDocument = `
    mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    clientMutationId
  }
}
    `;
export const useCreateTeamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateTeamMutation, TError, Types.CreateTeamMutationVariables, TContext>) =>
    useMutation<Types.CreateTeamMutation, TError, Types.CreateTeamMutationVariables, TContext>(
      ['CreateTeam'],
      useFetcher<Types.CreateTeamMutation, Types.CreateTeamMutationVariables>(CreateTeamDocument),
      options
    );
export const RemoveTeamDocument = `
    mutation RemoveTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input) {
    clientMutationId
  }
}
    `;
export const useRemoveTeamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveTeamMutation, TError, Types.RemoveTeamMutationVariables, TContext>) =>
    useMutation<Types.RemoveTeamMutation, TError, Types.RemoveTeamMutationVariables, TContext>(
      ['RemoveTeam'],
      useFetcher<Types.RemoveTeamMutation, Types.RemoveTeamMutationVariables>(RemoveTeamDocument),
      options
    );
export const LoadAppsForTeamDetailModalDocument = `
    query LoadAppsForTeamDetailModal($orgId: String!, $teamId: String!) {
  organization(id: $orgId) {
    apps {
      edges {
        node {
          id
          icon
          lastActivity
          name
          nativeType
          updated
          organization {
            name
          }
        }
      }
    }
    team(teamId: $teamId) {
      id
      description
      name
      apps {
        edges {
          node {
            id
            icon
            lastActivity
            name
            nativeType
            updated
            organization {
              name
            }
          }
        }
      }
      users {
        edges {
          node {
            id
            name
            email
            picture
            userId
            avatar
            userId
          }
        }
      }
    }
    users {
      edges {
        node {
          id
          name
          email
          picture
          userId
          avatar
        }
      }
    }
  }
}
    `;
export const useLoadAppsForTeamDetailModalQuery = <
      TData = Types.LoadAppsForTeamDetailModalQuery,
      TError = unknown
    >(
      variables: Types.LoadAppsForTeamDetailModalQueryVariables,
      options?: UseQueryOptions<Types.LoadAppsForTeamDetailModalQuery, TError, TData>
    ) =>
    useQuery<Types.LoadAppsForTeamDetailModalQuery, TError, TData>(
      ['LoadAppsForTeamDetailModal', variables],
      useFetcher<Types.LoadAppsForTeamDetailModalQuery, Types.LoadAppsForTeamDetailModalQueryVariables>(LoadAppsForTeamDetailModalDocument).bind(null, variables),
      options
    );
export const AddAppsToTeamDocument = `
    mutation AddAppsToTeam($input: AddAppsToTeamInput!) {
  createTeamAppAssociation(input: $input) {
    clientMutationId
  }
}
    `;
export const useAddAppsToTeamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddAppsToTeamMutation, TError, Types.AddAppsToTeamMutationVariables, TContext>) =>
    useMutation<Types.AddAppsToTeamMutation, TError, Types.AddAppsToTeamMutationVariables, TContext>(
      ['AddAppsToTeam'],
      useFetcher<Types.AddAppsToTeamMutation, Types.AddAppsToTeamMutationVariables>(AddAppsToTeamDocument),
      options
    );
export const AddTeamMembersDocument = `
    mutation AddTeamMembers($input: AddUsersToTeamInput!) {
  addTeamUsers(input: $input) {
    clientMutationId
  }
}
    `;
export const useAddTeamMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddTeamMembersMutation, TError, Types.AddTeamMembersMutationVariables, TContext>) =>
    useMutation<Types.AddTeamMembersMutation, TError, Types.AddTeamMembersMutationVariables, TContext>(
      ['AddTeamMembers'],
      useFetcher<Types.AddTeamMembersMutation, Types.AddTeamMembersMutationVariables>(AddTeamMembersDocument),
      options
    );
export const DeleteTeamAppAssociationDocument = `
    mutation DeleteTeamAppAssociation($input: DeleteTeamAppAssociationInput!) {
  deleteTeamAppAssociation(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteTeamAppAssociationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteTeamAppAssociationMutation, TError, Types.DeleteTeamAppAssociationMutationVariables, TContext>) =>
    useMutation<Types.DeleteTeamAppAssociationMutation, TError, Types.DeleteTeamAppAssociationMutationVariables, TContext>(
      ['DeleteTeamAppAssociation'],
      useFetcher<Types.DeleteTeamAppAssociationMutation, Types.DeleteTeamAppAssociationMutationVariables>(DeleteTeamAppAssociationDocument),
      options
    );
export const RemoveTeamMembersDocument = `
    mutation RemoveTeamMembers($input: RemoveTeamUsersInput!) {
  removeTeamUsers(input: $input) {
    clientMutationId
  }
}
    `;
export const useRemoveTeamMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveTeamMembersMutation, TError, Types.RemoveTeamMembersMutationVariables, TContext>) =>
    useMutation<Types.RemoveTeamMembersMutation, TError, Types.RemoveTeamMembersMutationVariables, TContext>(
      ['RemoveTeamMembers'],
      useFetcher<Types.RemoveTeamMembersMutation, Types.RemoveTeamMembersMutationVariables>(RemoveTeamMembersDocument),
      options
    );
export const UpdateOrgTeamDocument = `
    mutation UpdateOrgTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    clientMutationId
  }
}
    `;
export const useUpdateOrgTeamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOrgTeamMutation, TError, Types.UpdateOrgTeamMutationVariables, TContext>) =>
    useMutation<Types.UpdateOrgTeamMutation, TError, Types.UpdateOrgTeamMutationVariables, TContext>(
      ['UpdateOrgTeam'],
      useFetcher<Types.UpdateOrgTeamMutation, Types.UpdateOrgTeamMutationVariables>(UpdateOrgTeamDocument),
      options
    );
export const GetOrgTeamsDocument = `
    query GetOrgTeams($slug: String!) {
  organization(slug: $slug) {
    id
    availableSeats
    name
    slug
    teams {
      edges {
        node {
          ...TeamList
        }
      }
    }
    members {
      edges {
        node {
          name
          picture
          userId
        }
      }
    }
  }
}
    ${TeamListFragmentDoc}`;
export const useGetOrgTeamsQuery = <
      TData = Types.GetOrgTeamsQuery,
      TError = unknown
    >(
      variables: Types.GetOrgTeamsQueryVariables,
      options?: UseQueryOptions<Types.GetOrgTeamsQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgTeamsQuery, TError, TData>(
      ['GetOrgTeams', variables],
      useFetcher<Types.GetOrgTeamsQuery, Types.GetOrgTeamsQueryVariables>(GetOrgTeamsDocument).bind(null, variables),
      options
    );
export const GetDataForAppStorePublishingDocument = `
    query GetDataForAppStorePublishing($appId: String!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
    }
  }
}
    `;
export const useGetDataForAppStorePublishingQuery = <
      TData = Types.GetDataForAppStorePublishingQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAppStorePublishingQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAppStorePublishingQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAppStorePublishingQuery, TError, TData>(
      ['GetDataForAppStorePublishing', variables],
      useFetcher<Types.GetDataForAppStorePublishingQuery, Types.GetDataForAppStorePublishingQueryVariables>(GetDataForAppStorePublishingDocument).bind(null, variables),
      options
    );
export const GetDataForAuthConnectPluginDocument = `
    query GetDataForAuthConnectPlugin($id: String!) {
  app(id: $id) {
    name
    organization {
      slug
    }
    productKeys(first: 1) {
      edges {
        node {
          key
          packages {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForAuthConnectPluginQuery = <
      TData = Types.GetDataForAuthConnectPluginQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAuthConnectPluginQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAuthConnectPluginQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAuthConnectPluginQuery, TError, TData>(
      ['GetDataForAuthConnectPlugin', variables],
      useFetcher<Types.GetDataForAuthConnectPluginQuery, Types.GetDataForAuthConnectPluginQueryVariables>(GetDataForAuthConnectPluginDocument).bind(null, variables),
      options
    );
export const GetDataForCloudCliDocument = `
    query GetDataForCloudCLI($appId: String!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
    }
  }
}
    `;
export const useGetDataForCloudCliQuery = <
      TData = Types.GetDataForCloudCliQuery,
      TError = unknown
    >(
      variables: Types.GetDataForCloudCliQueryVariables,
      options?: UseQueryOptions<Types.GetDataForCloudCliQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForCloudCliQuery, TError, TData>(
      ['GetDataForCloudCLI', variables],
      useFetcher<Types.GetDataForCloudCliQuery, Types.GetDataForCloudCliQueryVariables>(GetDataForCloudCliDocument).bind(null, variables),
      options
    );
export const GetDataForCloudServicesDocument = `
    query GetDataForCloudServices($appId: String!) {
  app(id: $appId) {
    id
    nativeType
    organization {
      id
      slug
    }
  }
  viewer {
    id
  }
}
    `;
export const useGetDataForCloudServicesQuery = <
      TData = Types.GetDataForCloudServicesQuery,
      TError = unknown
    >(
      variables: Types.GetDataForCloudServicesQueryVariables,
      options?: UseQueryOptions<Types.GetDataForCloudServicesQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForCloudServicesQuery, TError, TData>(
      ['GetDataForCloudServices', variables],
      useFetcher<Types.GetDataForCloudServicesQuery, Types.GetDataForCloudServicesQueryVariables>(GetDataForCloudServicesDocument).bind(null, variables),
      options
    );
export const GetDataForIdentityVaultPluginDocument = `
    query GetDataForIdentityVaultPlugin($id: String!) {
  app(id: $id) {
    name
    organization {
      slug
    }
    productKeys(first: 1) {
      edges {
        node {
          key
          packages {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForIdentityVaultPluginQuery = <
      TData = Types.GetDataForIdentityVaultPluginQuery,
      TError = unknown
    >(
      variables: Types.GetDataForIdentityVaultPluginQueryVariables,
      options?: UseQueryOptions<Types.GetDataForIdentityVaultPluginQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForIdentityVaultPluginQuery, TError, TData>(
      ['GetDataForIdentityVaultPlugin', variables],
      useFetcher<Types.GetDataForIdentityVaultPluginQuery, Types.GetDataForIdentityVaultPluginQueryVariables>(GetDataForIdentityVaultPluginDocument).bind(null, variables),
      options
    );
export const GetDataForLiveUpdatesDocument = `
    query GetDataForLiveUpdates($appId: String!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
    }
  }
  viewer {
    id
    created
  }
}
    `;
export const useGetDataForLiveUpdatesQuery = <
      TData = Types.GetDataForLiveUpdatesQuery,
      TError = unknown
    >(
      variables: Types.GetDataForLiveUpdatesQueryVariables,
      options?: UseQueryOptions<Types.GetDataForLiveUpdatesQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForLiveUpdatesQuery, TError, TData>(
      ['GetDataForLiveUpdates', variables],
      useFetcher<Types.GetDataForLiveUpdatesQuery, Types.GetDataForLiveUpdatesQueryVariables>(GetDataForLiveUpdatesDocument).bind(null, variables),
      options
    );
export const GetDataForNativeBuildsDocument = `
    query GetDataForNativeBuilds($appId: String!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
    }
  }
  viewer {
    id
  }
}
    `;
export const useGetDataForNativeBuildsQuery = <
      TData = Types.GetDataForNativeBuildsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForNativeBuildsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForNativeBuildsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForNativeBuildsQuery, TError, TData>(
      ['GetDataForNativeBuilds', variables],
      useFetcher<Types.GetDataForNativeBuildsQuery, Types.GetDataForNativeBuildsQueryVariables>(GetDataForNativeBuildsDocument).bind(null, variables),
      options
    );
export const GetDataForNativeSolutionsDocument = `
    query GetDataForNativeSolutions($id: String!) {
  app(id: $id) {
    organization {
      slug
    }
    productKeys(first: 1) {
      edges {
        node {
          key
          packages {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForNativeSolutionsQuery = <
      TData = Types.GetDataForNativeSolutionsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForNativeSolutionsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForNativeSolutionsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForNativeSolutionsQuery, TError, TData>(
      ['GetDataForNativeSolutions', variables],
      useFetcher<Types.GetDataForNativeSolutionsQuery, Types.GetDataForNativeSolutionsQueryVariables>(GetDataForNativeSolutionsDocument).bind(null, variables),
      options
    );
export const GetDataForPaymentsPluginDocument = `
    query GetDataForPaymentsPlugin($id: String!) {
  app(id: $id) {
    id
    name
    organization {
      slug
    }
    productKeys(first: 1) {
      edges {
        node {
          key
          packages {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForPaymentsPluginQuery = <
      TData = Types.GetDataForPaymentsPluginQuery,
      TError = unknown
    >(
      variables: Types.GetDataForPaymentsPluginQueryVariables,
      options?: UseQueryOptions<Types.GetDataForPaymentsPluginQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForPaymentsPluginQuery, TError, TData>(
      ['GetDataForPaymentsPlugin', variables],
      useFetcher<Types.GetDataForPaymentsPluginQuery, Types.GetDataForPaymentsPluginQueryVariables>(GetDataForPaymentsPluginDocument).bind(null, variables),
      options
    );
export const GetDataForSecureStorageDocument = `
    query GetDataForSecureStorage($id: String!) {
  app(id: $id) {
    id
    name
    organization {
      slug
    }
    productKeys(first: 1) {
      edges {
        node {
          key
          packages {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetDataForSecureStorageQuery = <
      TData = Types.GetDataForSecureStorageQuery,
      TError = unknown
    >(
      variables: Types.GetDataForSecureStorageQueryVariables,
      options?: UseQueryOptions<Types.GetDataForSecureStorageQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForSecureStorageQuery, TError, TData>(
      ['GetDataForSecureStorage', variables],
      useFetcher<Types.GetDataForSecureStorageQuery, Types.GetDataForSecureStorageQueryVariables>(GetDataForSecureStorageDocument).bind(null, variables),
      options
    );
export const EnterpriseSubCardNativeKeyIdsDocument = `
    query EnterpriseSubCardNativeKeyIds($orgSlug: String!) {
  organization(slug: $orgSlug) {
    keys {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;
export const useEnterpriseSubCardNativeKeyIdsQuery = <
      TData = Types.EnterpriseSubCardNativeKeyIdsQuery,
      TError = unknown
    >(
      variables: Types.EnterpriseSubCardNativeKeyIdsQueryVariables,
      options?: UseQueryOptions<Types.EnterpriseSubCardNativeKeyIdsQuery, TError, TData>
    ) =>
    useQuery<Types.EnterpriseSubCardNativeKeyIdsQuery, TError, TData>(
      ['EnterpriseSubCardNativeKeyIds', variables],
      useFetcher<Types.EnterpriseSubCardNativeKeyIdsQuery, Types.EnterpriseSubCardNativeKeyIdsQueryVariables>(EnterpriseSubCardNativeKeyIdsDocument).bind(null, variables),
      options
    );
export const GetDataForAccountTypeSetupDocument = `
    query GetDataForAccountTypeSetup($slug: String!) {
  organization(slug: $slug) {
    id
    email
    name
    slug
  }
}
    `;
export const useGetDataForAccountTypeSetupQuery = <
      TData = Types.GetDataForAccountTypeSetupQuery,
      TError = unknown
    >(
      variables: Types.GetDataForAccountTypeSetupQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAccountTypeSetupQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAccountTypeSetupQuery, TError, TData>(
      ['GetDataForAccountTypeSetup', variables],
      useFetcher<Types.GetDataForAccountTypeSetupQuery, Types.GetDataForAccountTypeSetupQueryVariables>(GetDataForAccountTypeSetupDocument).bind(null, variables),
      options
    );
export const GetDataForPlanSetupDocument = `
    query GetDataForPlanSetup($slug: String!) {
  organization(slug: $slug) {
    id
    slug
    name
    email
  }
}
    `;
export const useGetDataForPlanSetupQuery = <
      TData = Types.GetDataForPlanSetupQuery,
      TError = unknown
    >(
      variables: Types.GetDataForPlanSetupQueryVariables,
      options?: UseQueryOptions<Types.GetDataForPlanSetupQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForPlanSetupQuery, TError, TData>(
      ['GetDataForPlanSetup', variables],
      useFetcher<Types.GetDataForPlanSetupQuery, Types.GetDataForPlanSetupQueryVariables>(GetDataForPlanSetupDocument).bind(null, variables),
      options
    );
export const GetDataForSubscriptionsDocument = `
    query GetDataForSubscriptions($slug: String!) {
  organization(slug: $slug) {
    id
    slug
    trialNative {
      __typename
      id
      trialType
      start
      end
      expired
    }
  }
  viewer {
    dashMetadata {
      data
    }
  }
}
    `;
export const useGetDataForSubscriptionsQuery = <
      TData = Types.GetDataForSubscriptionsQuery,
      TError = unknown
    >(
      variables: Types.GetDataForSubscriptionsQueryVariables,
      options?: UseQueryOptions<Types.GetDataForSubscriptionsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForSubscriptionsQuery, TError, TData>(
      ['GetDataForSubscriptions', variables],
      useFetcher<Types.GetDataForSubscriptionsQuery, Types.GetDataForSubscriptionsQueryVariables>(GetDataForSubscriptionsDocument).bind(null, variables),
      options
    );
export const GetLiveUpdateUsageDocument = `
    query GetLiveUpdateUsage($orgId: String, $appId: String!) {
  organization(id: $orgId) {
    billingCycleStartDate
    billingCycleEndDate
    deployCount(months: 1) {
      total
    }
    monthlyActiveUserCount(months: 1) {
      total
    }
  }
  app(id: $appId) {
    id
    monthlyDeployCount
    monthlyActiveUserCount
  }
}
    `;
export const useGetLiveUpdateUsageQuery = <
      TData = Types.GetLiveUpdateUsageQuery,
      TError = unknown
    >(
      variables: Types.GetLiveUpdateUsageQueryVariables,
      options?: UseQueryOptions<Types.GetLiveUpdateUsageQuery, TError, TData>
    ) =>
    useQuery<Types.GetLiveUpdateUsageQuery, TError, TData>(
      ['GetLiveUpdateUsage', variables],
      useFetcher<Types.GetLiveUpdateUsageQuery, Types.GetLiveUpdateUsageQueryVariables>(GetLiveUpdateUsageDocument).bind(null, variables),
      options
    );
export const GetOrgUsagePageDocument = `
    query GetOrgUsagePage($orgId: String!) {
  organization(id: $orgId) {
    billingCycleStartDate
    billingCycleEndDate
    apps(includeDeleted: true) {
      totalCount
      edges {
        node {
          ...MonthlyAppUsage
        }
      }
    }
  }
}
    ${MonthlyAppUsageFragmentDoc}`;
export const useGetOrgUsagePageQuery = <
      TData = Types.GetOrgUsagePageQuery,
      TError = unknown
    >(
      variables: Types.GetOrgUsagePageQueryVariables,
      options?: UseQueryOptions<Types.GetOrgUsagePageQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgUsagePageQuery, TError, TData>(
      ['GetOrgUsagePage', variables],
      useFetcher<Types.GetOrgUsagePageQuery, Types.GetOrgUsagePageQueryVariables>(GetOrgUsagePageDocument).bind(null, variables),
      options
    );
export const GetUsageHistoryDocument = `
    query GetUsageHistory($orgId: String!) {
  organization(id: $orgId) {
    buildCredits {
      ...UsageHistoryMonthlyUsage
    }
    buildMinutes {
      ...UsageHistoryMonthlyUsage
    }
    deployCount {
      ...UsageHistoryMonthlyUsage
    }
    monthlyActiveUserCount {
      ...UsageHistoryMonthlyUsage
    }
  }
}
    ${UsageHistoryMonthlyUsageFragmentDoc}`;
export const useGetUsageHistoryQuery = <
      TData = Types.GetUsageHistoryQuery,
      TError = unknown
    >(
      variables: Types.GetUsageHistoryQueryVariables,
      options?: UseQueryOptions<Types.GetUsageHistoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetUsageHistoryQuery, TError, TData>(
      ['GetUsageHistory', variables],
      useFetcher<Types.GetUsageHistoryQuery, Types.GetUsageHistoryQueryVariables>(GetUsageHistoryDocument).bind(null, variables),
      options
    );
export const GetDataForAccountPageDocument = `
    query GetDataForAccountPage {
  viewer {
    organizations {
      edges {
        node {
          sso
          slug
        }
      }
    }
  }
}
    `;
export const useGetDataForAccountPageQuery = <
      TData = Types.GetDataForAccountPageQuery,
      TError = unknown
    >(
      variables?: Types.GetDataForAccountPageQueryVariables,
      options?: UseQueryOptions<Types.GetDataForAccountPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForAccountPageQuery, TError, TData>(
      variables === undefined ? ['GetDataForAccountPage'] : ['GetDataForAccountPage', variables],
      useFetcher<Types.GetDataForAccountPageQuery, Types.GetDataForAccountPageQueryVariables>(GetDataForAccountPageDocument).bind(null, variables),
      options
    );
export const RemoveGitLinkForRevokeIntegrationModalDocument = `
    mutation RemoveGitLinkForRevokeIntegrationModal($gitType: RepositoryType!) {
  removeGitLink(input: {gitType: $gitType}) {
    success
  }
}
    `;
export const useRemoveGitLinkForRevokeIntegrationModalMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveGitLinkForRevokeIntegrationModalMutation, TError, Types.RemoveGitLinkForRevokeIntegrationModalMutationVariables, TContext>) =>
    useMutation<Types.RemoveGitLinkForRevokeIntegrationModalMutation, TError, Types.RemoveGitLinkForRevokeIntegrationModalMutationVariables, TContext>(
      ['RemoveGitLinkForRevokeIntegrationModal'],
      useFetcher<Types.RemoveGitLinkForRevokeIntegrationModalMutation, Types.RemoveGitLinkForRevokeIntegrationModalMutationVariables>(RemoveGitLinkForRevokeIntegrationModalDocument),
      options
    );
export const GetDataForPreviewScreenDocument = `
    query GetDataForPreviewScreen($id: String!) {
  app(id: $id) {
    name
    webPreview
  }
}
    `;
export const useGetDataForPreviewScreenQuery = <
      TData = Types.GetDataForPreviewScreenQuery,
      TError = unknown
    >(
      variables: Types.GetDataForPreviewScreenQueryVariables,
      options?: UseQueryOptions<Types.GetDataForPreviewScreenQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForPreviewScreenQuery, TError, TData>(
      ['GetDataForPreviewScreen', variables],
      useFetcher<Types.GetDataForPreviewScreenQuery, Types.GetDataForPreviewScreenQueryVariables>(GetDataForPreviewScreenDocument).bind(null, variables),
      options
    );
export const CreateAppWithRepoAssociationDocument = `
    mutation CreateAppWithRepoAssociation($input: CreateAndLinkAppInput!) {
  createAndLinkApp(input: $input) {
    app {
      ...AppDetailFields
    }
    webhook {
      url
      secret
    }
  }
}
    ${AppDetailFieldsFragmentDoc}`;
export const useCreateAppWithRepoAssociationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateAppWithRepoAssociationMutation, TError, Types.CreateAppWithRepoAssociationMutationVariables, TContext>) =>
    useMutation<Types.CreateAppWithRepoAssociationMutation, TError, Types.CreateAppWithRepoAssociationMutationVariables, TContext>(
      ['CreateAppWithRepoAssociation'],
      useFetcher<Types.CreateAppWithRepoAssociationMutation, Types.CreateAppWithRepoAssociationMutationVariables>(CreateAppWithRepoAssociationDocument),
      options
    );
export const CreateRepoAssociationDocument = `
    mutation CreateRepoAssociation($input: CreateRepositoryAssociationInput!) {
  createRepositoryAssociation(input: $input) {
    association {
      repository {
        id
      }
    }
    webhook {
      url
      secret
    }
  }
}
    `;
export const useCreateRepoAssociationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateRepoAssociationMutation, TError, Types.CreateRepoAssociationMutationVariables, TContext>) =>
    useMutation<Types.CreateRepoAssociationMutation, TError, Types.CreateRepoAssociationMutationVariables, TContext>(
      ['CreateRepoAssociation'],
      useFetcher<Types.CreateRepoAssociationMutation, Types.CreateRepoAssociationMutationVariables>(CreateRepoAssociationDocument),
      options
    );
export const DeleteRepoAssociationDocument = `
    mutation DeleteRepoAssociation($input: DeleteRepositoryAssociationInput!) {
  deleteRepositoryAssociation(input: $input) {
    association {
      repository {
        id
      }
    }
  }
}
    `;
export const useDeleteRepoAssociationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteRepoAssociationMutation, TError, Types.DeleteRepoAssociationMutationVariables, TContext>) =>
    useMutation<Types.DeleteRepoAssociationMutation, TError, Types.DeleteRepoAssociationMutationVariables, TContext>(
      ['DeleteRepoAssociation'],
      useFetcher<Types.DeleteRepoAssociationMutation, Types.DeleteRepoAssociationMutationVariables>(DeleteRepoAssociationDocument),
      options
    );
export const CreateAppDocument = `
    mutation CreateApp($input: CreateAppInput!) {
  createApp(input: $input) {
    app {
      ...AppDetailFields
    }
  }
}
    ${AppDetailFieldsFragmentDoc}`;
export const useCreateAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateAppMutation, TError, Types.CreateAppMutationVariables, TContext>) =>
    useMutation<Types.CreateAppMutation, TError, Types.CreateAppMutationVariables, TContext>(
      ['CreateApp'],
      useFetcher<Types.CreateAppMutation, Types.CreateAppMutationVariables>(CreateAppDocument),
      options
    );
export const UpdateAppDocument = `
    mutation UpdateApp($input: UpdateAppInput!) {
  updateApp(input: $input) {
    app {
      ...AppDetailFields
    }
  }
}
    ${AppDetailFieldsFragmentDoc}`;
export const useUpdateAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateAppMutation, TError, Types.UpdateAppMutationVariables, TContext>) =>
    useMutation<Types.UpdateAppMutation, TError, Types.UpdateAppMutationVariables, TContext>(
      ['UpdateApp'],
      useFetcher<Types.UpdateAppMutation, Types.UpdateAppMutationVariables>(UpdateAppDocument),
      options
    );
export const DeleteAppDocument = `
    mutation DeleteApp($input: DeleteAppInput!) {
  deleteApp(input: $input) {
    app {
      id
    }
  }
}
    `;
export const useDeleteAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteAppMutation, TError, Types.DeleteAppMutationVariables, TContext>) =>
    useMutation<Types.DeleteAppMutation, TError, Types.DeleteAppMutationVariables, TContext>(
      ['DeleteApp'],
      useFetcher<Types.DeleteAppMutation, Types.DeleteAppMutationVariables>(DeleteAppDocument),
      options
    );
export const CreatePersonalAccessTokenDocument = `
    mutation CreatePersonalAccessToken($input: CreatePersonalAccessTokenInput!) {
  createPersonalAccessToken(input: $input) {
    personalAccessToken {
      ...PersonalAccessTokenFields
    }
    token
  }
}
    ${PersonalAccessTokenFieldsFragmentDoc}`;
export const useCreatePersonalAccessTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePersonalAccessTokenMutation, TError, Types.CreatePersonalAccessTokenMutationVariables, TContext>) =>
    useMutation<Types.CreatePersonalAccessTokenMutation, TError, Types.CreatePersonalAccessTokenMutationVariables, TContext>(
      ['CreatePersonalAccessToken'],
      useFetcher<Types.CreatePersonalAccessTokenMutation, Types.CreatePersonalAccessTokenMutationVariables>(CreatePersonalAccessTokenDocument),
      options
    );
export const DeletePersonalAccessTokenDocument = `
    mutation DeletePersonalAccessToken($input: DeletePersonalAccessTokenInput!) {
  deletePersonalAccessToken(input: $input) {
    personalAccessToken {
      ...PersonalAccessTokenFields
    }
  }
}
    ${PersonalAccessTokenFieldsFragmentDoc}`;
export const useDeletePersonalAccessTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeletePersonalAccessTokenMutation, TError, Types.DeletePersonalAccessTokenMutationVariables, TContext>) =>
    useMutation<Types.DeletePersonalAccessTokenMutation, TError, Types.DeletePersonalAccessTokenMutationVariables, TContext>(
      ['DeletePersonalAccessToken'],
      useFetcher<Types.DeletePersonalAccessTokenMutation, Types.DeletePersonalAccessTokenMutationVariables>(DeletePersonalAccessTokenDocument),
      options
    );
export const CreateDeployBuildDocument = `
    mutation CreateDeployBuild($input: CreateDeployBuildInput!) {
  createDeployBuild(input: $input) {
    build {
      ...DeployBuildDetailFields
    }
  }
}
    ${DeployBuildDetailFieldsFragmentDoc}`;
export const useCreateDeployBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateDeployBuildMutation, TError, Types.CreateDeployBuildMutationVariables, TContext>) =>
    useMutation<Types.CreateDeployBuildMutation, TError, Types.CreateDeployBuildMutationVariables, TContext>(
      ['CreateDeployBuild'],
      useFetcher<Types.CreateDeployBuildMutation, Types.CreateDeployBuildMutationVariables>(CreateDeployBuildDocument),
      options
    );
export const UpdateWebBuildDocument = `
    mutation UpdateWebBuild($input: UpdateWebBuildInput!) {
  updateWebBuild(input: $input) {
    build {
      ...DeployBuildDetailFields
    }
  }
}
    ${DeployBuildDetailFieldsFragmentDoc}`;
export const useUpdateWebBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateWebBuildMutation, TError, Types.UpdateWebBuildMutationVariables, TContext>) =>
    useMutation<Types.UpdateWebBuildMutation, TError, Types.UpdateWebBuildMutationVariables, TContext>(
      ['UpdateWebBuild'],
      useFetcher<Types.UpdateWebBuildMutation, Types.UpdateWebBuildMutationVariables>(UpdateWebBuildDocument),
      options
    );
export const CreatePackageBuildDocument = `
    mutation CreatePackageBuild($input: CreatePackageBuildInput!) {
  createPackageBuild(input: $input) {
    build {
      ...PackageBuildDetailFields
    }
  }
}
    ${PackageBuildDetailFieldsFragmentDoc}`;
export const useCreatePackageBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePackageBuildMutation, TError, Types.CreatePackageBuildMutationVariables, TContext>) =>
    useMutation<Types.CreatePackageBuildMutation, TError, Types.CreatePackageBuildMutationVariables, TContext>(
      ['CreatePackageBuild'],
      useFetcher<Types.CreatePackageBuildMutation, Types.CreatePackageBuildMutationVariables>(CreatePackageBuildDocument),
      options
    );
export const CloneBuildDocument = `
    mutation CloneBuild($input: CloneBuildInput!) {
  cloneBuild(input: $input) {
    build {
      ...BuildDetailFields
    }
  }
}
    ${BuildDetailFieldsFragmentDoc}`;
export const useCloneBuildMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CloneBuildMutation, TError, Types.CloneBuildMutationVariables, TContext>) =>
    useMutation<Types.CloneBuildMutation, TError, Types.CloneBuildMutationVariables, TContext>(
      ['CloneBuild'],
      useFetcher<Types.CloneBuildMutation, Types.CloneBuildMutationVariables>(CloneBuildDocument),
      options
    );
export const CreateE2ETestDocument = `
    mutation CreateE2ETest($input: CreateIosTestInput!) {
  createIosTest(input: $input) {
    clientMutationId
    test {
      id
      jobId
    }
  }
}
    `;
export const useCreateE2ETestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateE2ETestMutation, TError, Types.CreateE2ETestMutationVariables, TContext>) =>
    useMutation<Types.CreateE2ETestMutation, TError, Types.CreateE2ETestMutationVariables, TContext>(
      ['CreateE2ETest'],
      useFetcher<Types.CreateE2ETestMutation, Types.CreateE2ETestMutationVariables>(CreateE2ETestDocument),
      options
    );
export const CreateE2ETestRunDocument = `
    mutation CreateE2ETestRun($input: CreateTestRunInput!) {
  createTestRun(input: $input) {
    clientMutationId
    testRun {
      id
      runNumber
      created
      label
      app {
        name
      }
      caller {
        name
      }
      commit {
        sha
        user {
          name
        }
      }
      tests {
        jobId
        device {
          id
          platform
          name
          osVersion
        }
        testRun {
          runNumber
        }
      }
    }
  }
}
    `;
export const useCreateE2ETestRunMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateE2ETestRunMutation, TError, Types.CreateE2ETestRunMutationVariables, TContext>) =>
    useMutation<Types.CreateE2ETestRunMutation, TError, Types.CreateE2ETestRunMutationVariables, TContext>(
      ['CreateE2ETestRun'],
      useFetcher<Types.CreateE2ETestRunMutation, Types.CreateE2ETestRunMutationVariables>(CreateE2ETestRunDocument),
      options
    );
export const UpdateEnvironmentDocument = `
    mutation UpdateEnvironment($id: Int!, $appId: String!, $config: [EnvironmentVariable!], $secrets: [EnvironmentVariable!], $name: String!) {
  updateEnvironment(
    input: {id: $id, appId: $appId, config: $config, secrets: $secrets, name: $name}
  ) {
    environment {
      environmentId
      config {
        name
        value
      }
      secrets {
        name
        value
      }
      name
    }
  }
}
    `;
export const useUpdateEnvironmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateEnvironmentMutation, TError, Types.UpdateEnvironmentMutationVariables, TContext>) =>
    useMutation<Types.UpdateEnvironmentMutation, TError, Types.UpdateEnvironmentMutationVariables, TContext>(
      ['UpdateEnvironment'],
      useFetcher<Types.UpdateEnvironmentMutation, Types.UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument),
      options
    );
export const DeleteEnvironmentDocument = `
    mutation DeleteEnvironment($id: Int!, $appId: String!) {
  deleteEnvironment(input: {id: $id, appId: $appId}) {
    success
  }
}
    `;
export const useDeleteEnvironmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteEnvironmentMutation, TError, Types.DeleteEnvironmentMutationVariables, TContext>) =>
    useMutation<Types.DeleteEnvironmentMutation, TError, Types.DeleteEnvironmentMutationVariables, TContext>(
      ['DeleteEnvironment'],
      useFetcher<Types.DeleteEnvironmentMutation, Types.DeleteEnvironmentMutationVariables>(DeleteEnvironmentDocument),
      options
    );
export const SubmitHubspotFormDocument = `
    mutation submitHubspotForm($input: SubmitHubspotFormInput!) {
  submitHubspotForm(input: $input) {
    response
  }
}
    `;
export const useSubmitHubspotFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SubmitHubspotFormMutation, TError, Types.SubmitHubspotFormMutationVariables, TContext>) =>
    useMutation<Types.SubmitHubspotFormMutation, TError, Types.SubmitHubspotFormMutationVariables, TContext>(
      ['submitHubspotForm'],
      useFetcher<Types.SubmitHubspotFormMutation, Types.SubmitHubspotFormMutationVariables>(SubmitHubspotFormDocument),
      options
    );
export const UpdateOrgDocument = `
    mutation UpdateOrg($input: UpdateOrgInput!) {
  updateOrg(input: $input) {
    organization {
      ...OrganizationFields
    }
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export const useUpdateOrgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOrgMutation, TError, Types.UpdateOrgMutationVariables, TContext>) =>
    useMutation<Types.UpdateOrgMutation, TError, Types.UpdateOrgMutationVariables, TContext>(
      ['UpdateOrg'],
      useFetcher<Types.UpdateOrgMutation, Types.UpdateOrgMutationVariables>(UpdateOrgDocument),
      options
    );
export const CreateS3DomainDocument = `
    mutation CreateS3Domain($input: CreateS3DomainInput!) {
  createS3Domain(input: $input) {
    s3Domain {
      accessKeyId
      bucket
      domain
      enabled
    }
  }
}
    `;
export const useCreateS3DomainMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateS3DomainMutation, TError, Types.CreateS3DomainMutationVariables, TContext>) =>
    useMutation<Types.CreateS3DomainMutation, TError, Types.CreateS3DomainMutationVariables, TContext>(
      ['CreateS3Domain'],
      useFetcher<Types.CreateS3DomainMutation, Types.CreateS3DomainMutationVariables>(CreateS3DomainDocument),
      options
    );
export const DeleteS3DomainDocument = `
    mutation DeleteS3Domain($input: DeleteS3DomainInput!) {
  deleteS3Domain(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteS3DomainMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteS3DomainMutation, TError, Types.DeleteS3DomainMutationVariables, TContext>) =>
    useMutation<Types.DeleteS3DomainMutation, TError, Types.DeleteS3DomainMutationVariables, TContext>(
      ['DeleteS3Domain'],
      useFetcher<Types.DeleteS3DomainMutation, Types.DeleteS3DomainMutationVariables>(DeleteS3DomainDocument),
      options
    );
export const UpdateS3DomainDocument = `
    mutation UpdateS3Domain($input: UpdateS3DomainInput!) {
  updateS3Domain(input: $input) {
    s3Domain {
      accessKeyId
      bucket
      domain
      enabled
    }
  }
}
    `;
export const useUpdateS3DomainMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateS3DomainMutation, TError, Types.UpdateS3DomainMutationVariables, TContext>) =>
    useMutation<Types.UpdateS3DomainMutation, TError, Types.UpdateS3DomainMutationVariables, TContext>(
      ['UpdateS3Domain'],
      useFetcher<Types.UpdateS3DomainMutation, Types.UpdateS3DomainMutationVariables>(UpdateS3DomainDocument),
      options
    );
export const CreateSshKeyDocument = `
    mutation CreateSSHKey($input: CreateSSHKeyInput!) {
  createSshKey(input: $input) {
    sshKey {
      ...SSHKeyFields
    }
  }
}
    ${SshKeyFieldsFragmentDoc}`;
export const useCreateSshKeyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSshKeyMutation, TError, Types.CreateSshKeyMutationVariables, TContext>) =>
    useMutation<Types.CreateSshKeyMutation, TError, Types.CreateSshKeyMutationVariables, TContext>(
      ['CreateSSHKey'],
      useFetcher<Types.CreateSshKeyMutation, Types.CreateSshKeyMutationVariables>(CreateSshKeyDocument),
      options
    );
export const DeleteSshKeyDocument = `
    mutation DeleteSSHKey($input: DeleteSSHKeyInput!) {
  deleteSshKey(input: $input) {
    sshKey {
      ...SSHKeyFields
    }
  }
}
    ${SshKeyFieldsFragmentDoc}`;
export const useDeleteSshKeyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteSshKeyMutation, TError, Types.DeleteSshKeyMutationVariables, TContext>) =>
    useMutation<Types.DeleteSshKeyMutation, TError, Types.DeleteSshKeyMutationVariables, TContext>(
      ['DeleteSSHKey'],
      useFetcher<Types.DeleteSshKeyMutation, Types.DeleteSshKeyMutationVariables>(DeleteSshKeyDocument),
      options
    );
export const StartTrialDocument = `
    mutation StartTrial($input: StartTrialInput!) {
  startTrial(input: $input) {
    trial {
      id
      trialType
      start
      end
      userId
      organizationId
    }
  }
}
    `;
export const useStartTrialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.StartTrialMutation, TError, Types.StartTrialMutationVariables, TContext>) =>
    useMutation<Types.StartTrialMutation, TError, Types.StartTrialMutationVariables, TContext>(
      ['StartTrial'],
      useFetcher<Types.StartTrialMutation, Types.StartTrialMutationVariables>(StartTrialDocument),
      options
    );
export const ResendVerificationDocument = `
    mutation ResendVerification($input: ResendVerificationInput!) {
  resendVerification(input: $input) {
    accepted
  }
}
    `;
export const useResendVerificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ResendVerificationMutation, TError, Types.ResendVerificationMutationVariables, TContext>) =>
    useMutation<Types.ResendVerificationMutation, TError, Types.ResendVerificationMutationVariables, TContext>(
      ['ResendVerification'],
      useFetcher<Types.ResendVerificationMutation, Types.ResendVerificationMutationVariables>(ResendVerificationDocument),
      options
    );
export const UpdateDashboardUserMetadataDocument = `
    mutation UpdateDashboardUserMetadata($input: UpdateDashboardUserMetadataInput!) {
  updateDashboardUserMetadata(input: $input) {
    meta {
      data
    }
  }
}
    `;
export const useUpdateDashboardUserMetadataMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateDashboardUserMetadataMutation, TError, Types.UpdateDashboardUserMetadataMutationVariables, TContext>) =>
    useMutation<Types.UpdateDashboardUserMetadataMutation, TError, Types.UpdateDashboardUserMetadataMutationVariables, TContext>(
      ['UpdateDashboardUserMetadata'],
      useFetcher<Types.UpdateDashboardUserMetadataMutation, Types.UpdateDashboardUserMetadataMutationVariables>(UpdateDashboardUserMetadataDocument),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...UserDetailFields
    }
  }
}
    ${UserDetailFieldsFragmentDoc}`;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateUserMutation, TError, Types.UpdateUserMutationVariables, TContext>) =>
    useMutation<Types.UpdateUserMutation, TError, Types.UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      useFetcher<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    clientMutationId
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteUserMutation, TError, Types.DeleteUserMutationVariables, TContext>) =>
    useMutation<Types.DeleteUserMutation, TError, Types.DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      useFetcher<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument),
      options
    );
export const AppKeysDocument = `
    query AppKeys($id: String!) {
  app(id: $id) {
    productKeys(first: 1) {
      edges {
        node {
          ...ProductKeyFields
        }
      }
    }
  }
}
    ${ProductKeyFieldsFragmentDoc}`;
export const useAppKeysQuery = <
      TData = Types.AppKeysQuery,
      TError = unknown
    >(
      variables: Types.AppKeysQueryVariables,
      options?: UseQueryOptions<Types.AppKeysQuery, TError, TData>
    ) =>
    useQuery<Types.AppKeysQuery, TError, TData>(
      ['AppKeys', variables],
      useFetcher<Types.AppKeysQuery, Types.AppKeysQueryVariables>(AppKeysDocument).bind(null, variables),
      options
    );
export const PersonalAppsDocument = `
    query PersonalApps($first: Int, $last: Int, $after: String, $before: String) {
  viewer {
    apps(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...AppListFields
        }
      }
      totalCount
    }
  }
}
    ${AppListFieldsFragmentDoc}`;
export const usePersonalAppsQuery = <
      TData = Types.PersonalAppsQuery,
      TError = unknown
    >(
      variables?: Types.PersonalAppsQueryVariables,
      options?: UseQueryOptions<Types.PersonalAppsQuery, TError, TData>
    ) =>
    useQuery<Types.PersonalAppsQuery, TError, TData>(
      variables === undefined ? ['PersonalApps'] : ['PersonalApps', variables],
      useFetcher<Types.PersonalAppsQuery, Types.PersonalAppsQueryVariables>(PersonalAppsDocument).bind(null, variables),
      options
    );
export const GetAppByIdDocument = `
    query GetAppById($id: String!) {
  app(id: $id) {
    id
    createdByWizard
    icon
    name
    slug
    webPreview
    nativeType
    dependencyCaching
    association {
      repository {
        cloneUrl
        type
        fullName
        htmlUrl
        hasWebhook
      }
    }
    organization {
      id
      slug
      name
      buildCreditsAvailable
      plan
    }
    owner {
      username
    }
  }
}
    `;
export const useGetAppByIdQuery = <
      TData = Types.GetAppByIdQuery,
      TError = unknown
    >(
      variables: Types.GetAppByIdQueryVariables,
      options?: UseQueryOptions<Types.GetAppByIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetAppByIdQuery, TError, TData>(
      ['GetAppById', variables],
      useFetcher<Types.GetAppByIdQuery, Types.GetAppByIdQueryVariables>(GetAppByIdDocument).bind(null, variables),
      options
    );
export const GetAllAppsDocument = `
    query GetAllApps {
  apps {
    edges {
      node {
        ...AppListFields
      }
    }
    totalCount
  }
}
    ${AppListFieldsFragmentDoc}`;
export const useGetAllAppsQuery = <
      TData = Types.GetAllAppsQuery,
      TError = unknown
    >(
      variables?: Types.GetAllAppsQueryVariables,
      options?: UseQueryOptions<Types.GetAllAppsQuery, TError, TData>
    ) =>
    useQuery<Types.GetAllAppsQuery, TError, TData>(
      variables === undefined ? ['GetAllApps'] : ['GetAllApps', variables],
      useFetcher<Types.GetAllAppsQuery, Types.GetAllAppsQueryVariables>(GetAllAppsDocument).bind(null, variables),
      options
    );
export const OrganizationAppsDocument = `
    query OrganizationApps($slug: String!, $first: Int, $last: Int, $after: String, $before: String) {
  organization(slug: $slug) {
    apps(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...AppListFields
        }
      }
      totalCount
    }
  }
}
    ${AppListFieldsFragmentDoc}`;
export const useOrganizationAppsQuery = <
      TData = Types.OrganizationAppsQuery,
      TError = unknown
    >(
      variables: Types.OrganizationAppsQueryVariables,
      options?: UseQueryOptions<Types.OrganizationAppsQuery, TError, TData>
    ) =>
    useQuery<Types.OrganizationAppsQuery, TError, TData>(
      ['OrganizationApps', variables],
      useFetcher<Types.OrganizationAppsQuery, Types.OrganizationAppsQueryVariables>(OrganizationAppsDocument).bind(null, variables),
      options
    );
export const BuildStacksDocument = `
    query BuildStacks($nativeType: AppNativeType) {
  stacks(nativeType: $nativeType) {
    ...BuildStackDetails
  }
}
    ${BuildStackDetailsFragmentDoc}`;
export const useBuildStacksQuery = <
      TData = Types.BuildStacksQuery,
      TError = unknown
    >(
      variables?: Types.BuildStacksQueryVariables,
      options?: UseQueryOptions<Types.BuildStacksQuery, TError, TData>
    ) =>
    useQuery<Types.BuildStacksQuery, TError, TData>(
      variables === undefined ? ['BuildStacks'] : ['BuildStacks', variables],
      useFetcher<Types.BuildStacksQuery, Types.BuildStacksQueryVariables>(BuildStacksDocument).bind(null, variables),
      options
    );
export const BuildByIdDocument = `
    query BuildById($appId: String!, $buildId: Int!) {
  app(id: $appId) {
    build(buildId: $buildId) {
      ...BuildDetailFields
      ...DeployBuildDetailFields
      ...PackageBuildDetailFields
    }
  }
}
    ${BuildDetailFieldsFragmentDoc}
${DeployBuildDetailFieldsFragmentDoc}
${PackageBuildDetailFieldsFragmentDoc}`;
export const useBuildByIdQuery = <
      TData = Types.BuildByIdQuery,
      TError = unknown
    >(
      variables: Types.BuildByIdQueryVariables,
      options?: UseQueryOptions<Types.BuildByIdQuery, TError, TData>
    ) =>
    useQuery<Types.BuildByIdQuery, TError, TData>(
      ['BuildById', variables],
      useFetcher<Types.BuildByIdQuery, Types.BuildByIdQueryVariables>(BuildByIdDocument).bind(null, variables),
      options
    );
export const BuildsListDocument = `
    query BuildsList($appId: String!, $first: Int, $last: Int, $after: String, $before: String, $state: JobState, $platform: Platform, $deployable: Boolean) {
  app(id: $appId) {
    builds(
      first: $first
      last: $last
      after: $after
      before: $before
      state: $state
      platform: $platform
      deployable: $deployable
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          __typename
          ...BuildListFields
          ...DeployBuildListFields
          ...PackageBuildListFields
        }
      }
    }
  }
}
    ${BuildListFieldsFragmentDoc}
${DeployBuildListFieldsFragmentDoc}
${PackageBuildListFieldsFragmentDoc}`;
export const useBuildsListQuery = <
      TData = Types.BuildsListQuery,
      TError = unknown
    >(
      variables: Types.BuildsListQueryVariables,
      options?: UseQueryOptions<Types.BuildsListQuery, TError, TData>
    ) =>
    useQuery<Types.BuildsListQuery, TError, TData>(
      ['BuildsList', variables],
      useFetcher<Types.BuildsListQuery, Types.BuildsListQueryVariables>(BuildsListDocument).bind(null, variables),
      options
    );
export const GetCertificateListByAppIdDocument = `
    query GetCertificateListByAppId($appId: String!) {
  app(id: $appId) {
    certificates {
      edges {
        node {
          ...CertificateDetails
        }
      }
    }
  }
}
    ${CertificateDetailsFragmentDoc}`;
export const useGetCertificateListByAppIdQuery = <
      TData = Types.GetCertificateListByAppIdQuery,
      TError = unknown
    >(
      variables: Types.GetCertificateListByAppIdQueryVariables,
      options?: UseQueryOptions<Types.GetCertificateListByAppIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetCertificateListByAppIdQuery, TError, TData>(
      ['GetCertificateListByAppId', variables],
      useFetcher<Types.GetCertificateListByAppIdQuery, Types.GetCertificateListByAppIdQueryVariables>(GetCertificateListByAppIdDocument).bind(null, variables),
      options
    );
export const GetCommitByIdDocument = `
    query GetCommitById($appId: String!, $commitId: Int!) {
  app(id: $appId) {
    commit(commitId: $commitId) {
      ...CommitFields
    }
  }
}
    ${CommitFieldsFragmentDoc}`;
export const useGetCommitByIdQuery = <
      TData = Types.GetCommitByIdQuery,
      TError = unknown
    >(
      variables: Types.GetCommitByIdQueryVariables,
      options?: UseQueryOptions<Types.GetCommitByIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetCommitByIdQuery, TError, TData>(
      ['GetCommitById', variables],
      useFetcher<Types.GetCommitByIdQuery, Types.GetCommitByIdQueryVariables>(GetCommitByIdDocument).bind(null, variables),
      options
    );
export const GetDeploymentDocument = `
    query GetDeployment($appId: String!, $deploymentId: Int!) {
  app(id: $appId) {
    id
    organization {
      id
      slug
      buildCreditsAvailable
    }
    deployment(deploymentId: $deploymentId) {
      ...DeploymentDetailFields
      ...BinaryDeploymentDetailFields
      ...LiveUpdateDeploymentDetailFields
    }
  }
}
    ${DeploymentDetailFieldsFragmentDoc}
${BinaryDeploymentDetailFieldsFragmentDoc}
${LiveUpdateDeploymentDetailFieldsFragmentDoc}`;
export const useGetDeploymentQuery = <
      TData = Types.GetDeploymentQuery,
      TError = unknown
    >(
      variables: Types.GetDeploymentQueryVariables,
      options?: UseQueryOptions<Types.GetDeploymentQuery, TError, TData>
    ) =>
    useQuery<Types.GetDeploymentQuery, TError, TData>(
      ['GetDeployment', variables],
      useFetcher<Types.GetDeploymentQuery, Types.GetDeploymentQueryVariables>(GetDeploymentDocument).bind(null, variables),
      options
    );
export const GetE2EStacksDocument = `
    query GetE2EStacks {
  stacks {
    ...E2EBuildStacks
  }
}
    ${E2EBuildStacksFragmentDoc}`;
export const useGetE2EStacksQuery = <
      TData = Types.GetE2EStacksQuery,
      TError = unknown
    >(
      variables?: Types.GetE2EStacksQueryVariables,
      options?: UseQueryOptions<Types.GetE2EStacksQuery, TError, TData>
    ) =>
    useQuery<Types.GetE2EStacksQuery, TError, TData>(
      variables === undefined ? ['GetE2EStacks'] : ['GetE2EStacks', variables],
      useFetcher<Types.GetE2EStacksQuery, Types.GetE2EStacksQueryVariables>(GetE2EStacksDocument).bind(null, variables),
      options
    );
export const GetE2ETestRunDocument = `
    query GetE2ETestRun($appId: String!, $e2eTestRunNumber: Int!) {
  app(id: $appId) {
    e2eTestRun(runNumber: $e2eTestRunNumber) {
      ...E2ETestRunListFields
    }
  }
}
    ${E2ETestRunListFieldsFragmentDoc}`;
export const useGetE2ETestRunQuery = <
      TData = Types.GetE2ETestRunQuery,
      TError = unknown
    >(
      variables: Types.GetE2ETestRunQueryVariables,
      options?: UseQueryOptions<Types.GetE2ETestRunQuery, TError, TData>
    ) =>
    useQuery<Types.GetE2ETestRunQuery, TError, TData>(
      ['GetE2ETestRun', variables],
      useFetcher<Types.GetE2ETestRunQuery, Types.GetE2ETestRunQueryVariables>(GetE2ETestRunDocument).bind(null, variables),
      options
    );
export const GetE2ETestDetailsDocument = `
    query GetE2ETestDetails($appId: String!, $e2eTestId: Int!) {
  app(id: $appId) {
    e2eTest(id: $e2eTestId) {
      ...E2ETestDetailsFields
    }
  }
}
    ${E2ETestDetailsFieldsFragmentDoc}`;
export const useGetE2ETestDetailsQuery = <
      TData = Types.GetE2ETestDetailsQuery,
      TError = unknown
    >(
      variables: Types.GetE2ETestDetailsQueryVariables,
      options?: UseQueryOptions<Types.GetE2ETestDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.GetE2ETestDetailsQuery, TError, TData>(
      ['GetE2ETestDetails', variables],
      useFetcher<Types.GetE2ETestDetailsQuery, Types.GetE2ETestDetailsQueryVariables>(GetE2ETestDetailsDocument).bind(null, variables),
      options
    );
export const OrgNativeKeysDocument = `
    query OrgNativeKeys($orgSlug: String!) {
  organization(slug: $orgSlug) {
    id
    hasPaidNativeKeys
    keys {
      edges {
        node {
          id
          app {
            id
            icon
            name
          }
          key
          packages {
            edges {
              node {
                id
                name
              }
            }
          }
          trial {
            expired
          }
        }
      }
    }
  }
}
    `;
export const useOrgNativeKeysQuery = <
      TData = Types.OrgNativeKeysQuery,
      TError = unknown
    >(
      variables: Types.OrgNativeKeysQueryVariables,
      options?: UseQueryOptions<Types.OrgNativeKeysQuery, TError, TData>
    ) =>
    useQuery<Types.OrgNativeKeysQuery, TError, TData>(
      ['OrgNativeKeys', variables],
      useFetcher<Types.OrgNativeKeysQuery, Types.OrgNativeKeysQueryVariables>(OrgNativeKeysDocument).bind(null, variables),
      options
    );
export const OrganizationBySlugDocument = `
    query OrganizationBySlug($slug: String!) {
  organization(slug: $slug) {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export const useOrganizationBySlugQuery = <
      TData = Types.OrganizationBySlugQuery,
      TError = unknown
    >(
      variables: Types.OrganizationBySlugQueryVariables,
      options?: UseQueryOptions<Types.OrganizationBySlugQuery, TError, TData>
    ) =>
    useQuery<Types.OrganizationBySlugQuery, TError, TData>(
      ['OrganizationBySlug', variables],
      useFetcher<Types.OrganizationBySlugQuery, Types.OrganizationBySlugQueryVariables>(OrganizationBySlugDocument).bind(null, variables),
      options
    );
export const GetOrgMembersDocument = `
    query GetOrgMembers($orgId: String!) {
  organization(id: $orgId) {
    members {
      edges {
        node {
          role
          userId
        }
      }
    }
  }
}
    `;
export const useGetOrgMembersQuery = <
      TData = Types.GetOrgMembersQuery,
      TError = unknown
    >(
      variables: Types.GetOrgMembersQueryVariables,
      options?: UseQueryOptions<Types.GetOrgMembersQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrgMembersQuery, TError, TData>(
      ['GetOrgMembers', variables],
      useFetcher<Types.GetOrgMembersQuery, Types.GetOrgMembersQueryVariables>(GetOrgMembersDocument).bind(null, variables),
      options
    );
export const GetPortalsKeysDocument = `
    query GetPortalsKeys {
  viewer {
    id
    dashMetadata {
      data
    }
    organizations {
      edges {
        node {
          id
          portalsKey
        }
      }
    }
  }
}
    `;
export const useGetPortalsKeysQuery = <
      TData = Types.GetPortalsKeysQuery,
      TError = unknown
    >(
      variables?: Types.GetPortalsKeysQueryVariables,
      options?: UseQueryOptions<Types.GetPortalsKeysQuery, TError, TData>
    ) =>
    useQuery<Types.GetPortalsKeysQuery, TError, TData>(
      variables === undefined ? ['GetPortalsKeys'] : ['GetPortalsKeys', variables],
      useFetcher<Types.GetPortalsKeysQuery, Types.GetPortalsKeysQueryVariables>(GetPortalsKeysDocument).bind(null, variables),
      options
    );
export const GetAppRepositoryBranchesDocument = `
    query GetAppRepositoryBranches($id: String!) {
  app(id: $id) {
    association {
      branches
    }
  }
}
    `;
export const useGetAppRepositoryBranchesQuery = <
      TData = Types.GetAppRepositoryBranchesQuery,
      TError = unknown
    >(
      variables: Types.GetAppRepositoryBranchesQueryVariables,
      options?: UseQueryOptions<Types.GetAppRepositoryBranchesQuery, TError, TData>
    ) =>
    useQuery<Types.GetAppRepositoryBranchesQuery, TError, TData>(
      ['GetAppRepositoryBranches', variables],
      useFetcher<Types.GetAppRepositoryBranchesQuery, Types.GetAppRepositoryBranchesQueryVariables>(GetAppRepositoryBranchesDocument).bind(null, variables),
      options
    );
export const AppS3DomainDocument = `
    query AppS3Domain($id: String!) {
  app(id: $id) {
    s3Domain {
      ...S3DomainFields
    }
  }
}
    ${S3DomainFieldsFragmentDoc}`;
export const useAppS3DomainQuery = <
      TData = Types.AppS3DomainQuery,
      TError = unknown
    >(
      variables: Types.AppS3DomainQueryVariables,
      options?: UseQueryOptions<Types.AppS3DomainQuery, TError, TData>
    ) =>
    useQuery<Types.AppS3DomainQuery, TError, TData>(
      ['AppS3Domain', variables],
      useFetcher<Types.AppS3DomainQuery, Types.AppS3DomainQueryVariables>(AppS3DomainDocument).bind(null, variables),
      options
    );
export const UserSshKeysDocument = `
    query UserSSHKeys($userId: Int!) {
  user(userId: $userId) {
    sshKeys {
      edges {
        node {
          ...SSHKeyFields
        }
      }
    }
  }
}
    ${SshKeyFieldsFragmentDoc}`;
export const useUserSshKeysQuery = <
      TData = Types.UserSshKeysQuery,
      TError = unknown
    >(
      variables: Types.UserSshKeysQueryVariables,
      options?: UseQueryOptions<Types.UserSshKeysQuery, TError, TData>
    ) =>
    useQuery<Types.UserSshKeysQuery, TError, TData>(
      ['UserSSHKeys', variables],
      useFetcher<Types.UserSshKeysQuery, Types.UserSshKeysQueryVariables>(UserSshKeysDocument).bind(null, variables),
      options
    );
export const GetCurrentUserDocument = `
    query GetCurrentUser {
  viewer {
    ...UserDetailFields
  }
}
    ${UserDetailFieldsFragmentDoc}`;
export const useGetCurrentUserQuery = <
      TData = Types.GetCurrentUserQuery,
      TError = unknown
    >(
      variables?: Types.GetCurrentUserQueryVariables,
      options?: UseQueryOptions<Types.GetCurrentUserQuery, TError, TData>
    ) =>
    useQuery<Types.GetCurrentUserQuery, TError, TData>(
      variables === undefined ? ['GetCurrentUser'] : ['GetCurrentUser', variables],
      useFetcher<Types.GetCurrentUserQuery, Types.GetCurrentUserQueryVariables>(GetCurrentUserDocument).bind(null, variables),
      options
    );
export const GetUserByIdDocument = `
    query GetUserById($userId: Int!) {
  user(userId: $userId) {
    ...UserDetailFields
  }
}
    ${UserDetailFieldsFragmentDoc}`;
export const useGetUserByIdQuery = <
      TData = Types.GetUserByIdQuery,
      TError = unknown
    >(
      variables: Types.GetUserByIdQueryVariables,
      options?: UseQueryOptions<Types.GetUserByIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetUserByIdQuery, TError, TData>(
      ['GetUserById', variables],
      useFetcher<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>(GetUserByIdDocument).bind(null, variables),
      options
    );
export const GetDataForCurrentOrgSlugDocument = `
    query GetDataForCurrentOrgSlug($appId: String!) {
  app(id: $appId) {
    organization {
      slug
    }
  }
}
    `;
export const useGetDataForCurrentOrgSlugQuery = <
      TData = Types.GetDataForCurrentOrgSlugQuery,
      TError = unknown
    >(
      variables: Types.GetDataForCurrentOrgSlugQueryVariables,
      options?: UseQueryOptions<Types.GetDataForCurrentOrgSlugQuery, TError, TData>
    ) =>
    useQuery<Types.GetDataForCurrentOrgSlugQuery, TError, TData>(
      ['GetDataForCurrentOrgSlug', variables],
      useFetcher<Types.GetDataForCurrentOrgSlugQuery, Types.GetDataForCurrentOrgSlugQueryVariables>(GetDataForCurrentOrgSlugDocument).bind(null, variables),
      options
    );